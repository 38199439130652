import { useState, useEffect } from 'react';
import axios from 'axios';

const usePaginatedData = (initialUrl, initialPage = 1, productId = null, categoryId = null) => {
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(initialPage);
    const [totalPages, setTotalPages] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchData(currentPage);
    }, [currentPage]);

    const fetchData = async (page) => {
        setLoading(true);
        setError(null);
        try {
            let url = `${initialUrl}?page=${page}`;
            if (productId) {
                url += `&product_id=${productId}`;
            }
            if (categoryId) {
                url += `&category_id=${categoryId}`;
            }

            const response = await axios.get(url);
            setData(prevData => [...prevData, ...response.data.data.products]);
            setTotalPages(response.data.data.pagination.total_pages);
            setTotalCount(response.data.data.pagination.total);
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    const loadMore = () => {
        if (currentPage < totalPages && data.length < totalCount) {
            setCurrentPage(prevPage => prevPage + 1);
        }
    };

    return { data, currentPage, totalPages, totalCount, loading, error, loadMore };
};

export default usePaginatedData;
