class MessageParser {
  constructor(actionProvider) {
    this.actionProvider = actionProvider;
  }

  parse(message) {
    const lowerCaseMessage = message.toLowerCase();

    if (lowerCaseMessage.includes("hello") || lowerCaseMessage.includes("hi")) {
      this.actionProvider.handleHello();
    } else if (lowerCaseMessage.includes("order status")) {
      this.actionProvider.handleOrderStatus();
    } else if (lowerCaseMessage.includes("shipping")) {
      this.actionProvider.handleShipping();
    } else if (lowerCaseMessage.includes("return")) {
      this.actionProvider.handleReturnPolicy();
    } else if (lowerCaseMessage.includes("payment options")) {
      this.actionProvider.handlePaymentOptions();
    } else if (lowerCaseMessage.includes("refund")) {
      this.actionProvider.handleRefund();
    } else if (lowerCaseMessage.includes("discount")) {
      this.actionProvider.handleDiscounts();
    } else if (lowerCaseMessage.includes("support")) {
      this.actionProvider.handleCustomerSupport();
    } else {
      this.actionProvider.handleUnknown();
    }
  }
}

export default MessageParser;
