import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useTheme } from '@mui/material/styles';
import { alpha } from '@mui/material/styles';

const CouponItem = ({ coupon, selectedCoupon, onSelect }) => {
  const theme = useTheme();
  const dateString = coupon.end_date;
  const date = new Date(dateString);

  const options = { day: 'numeric', month: 'long', year: 'numeric' };
  const timeOptions = { hour: '2-digit', minute: '2-digit', hour12: true, hourCycle: 'h12' };
  const expiryDate = date.toLocaleDateString('en-GB', options);
  const expiryTime = date.toLocaleTimeString('en-GB', timeOptions);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        border: selectedCoupon && selectedCoupon.id === coupon.id ?
          `2px dashed ${theme.palette.primary.main}` :
          `1px solid #ccc`,
        borderRadius: 2,
        padding: 2,
        mb: 2,
        backgroundColor: selectedCoupon && selectedCoupon.id === coupon.id ?
          alpha(theme.palette.primary.main, 0.2) :
          '#fff',
      }}
    >
      <FormControlLabel
        control={
          <Checkbox
            checked={selectedCoupon && selectedCoupon.id === coupon.id}
            onChange={() => onSelect(coupon)}
          />
        }
        label={
          <Typography color="primary" sx={{ fontWeight: 'bold' }}>
            {coupon.coupon_code}
          </Typography>
        }
      />
      <Typography gutterBottom variant='body1' sx={{ fontWeight: 'bold' }}>
        Save {coupon.description}
      </Typography>
      <Typography gutterBottom variant='body2'>
        {coupon.discount_type === "percentage" ?
          `${coupon.discount_amount}% off on minimum purchase of Rs. ${coupon.minimum_purchase}.` :
          `₹${parseInt(coupon.discount_amount)} off on minimum purchase of Rs. ${coupon.minimum_purchase}.`
        }
      </Typography>
      <Typography variant="body2" color="textSecondary">
        Expires on: {expiryDate}, {expiryTime}
      </Typography>
    </Box>
  );
};

export default CouponItem;
