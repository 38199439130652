import React from 'react';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import { useThemeContext } from '../../context/ThemeContext';
import IconButton from '@mui/material/IconButton';
import Paper from "@mui/material/Paper"

const ThemeToggleButton = () => {
  const { theme, toggleTheme } = useThemeContext();

  return (
    <Paper elevation={2} sx={{borderRadius:"50%",backgroundColor:theme.palette.primary.main}}>
       <IconButton onClick={toggleTheme} >
      {theme.palette.mode === "light" ? <Brightness4Icon fontSize='small'  sx={{color:"white"}}/> : <Brightness7Icon fontSize='small' sx={{color:"white"}} />}
    </IconButton>

    </Paper>
   
  );
};

export default ThemeToggleButton;
