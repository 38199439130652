import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';
import { Navigation, Scrollbar } from 'swiper/modules';
import Container from '@mui/material/Container';
import ProductCard from '../product/ProductCard';
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import useMediaQuery from '@mui/material/useMediaQuery';
import SectionTitle from '../core/SectionTitle';
import CustomSlider from './CustomSlider';

export default function SpecialProducts({ specialProducts }) {
  const theme = useTheme();

  
  return (
    <Container maxWidth="lg" sx={{ mt: 10 }}>
      <SectionTitle t1={"Special Products"} t2={"Featured and Exclusive Finds"} />

      <CustomSlider
        items={specialProducts}
        renderSlide={(item) => <ProductCard productData={item}/>}
        maxSlides={10}
        navigationClass="special-product"
      />
    </Container>
  );
}
