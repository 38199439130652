import React, { useState, useEffect } from 'react'
import ChatBot from './ChatBot';
import IconButton from '@mui/material/IconButton';
import ChatIcon from '@mui/icons-material/Chat';
import { useTheme } from "@mui/material/styles"
import { useLocation } from 'react-router-dom';
import CancelIcon from "@mui/icons-material/Cancel"
import Box from "@mui/material/Box"
import { useMediaQuery } from '@mui/material';

export default function ChatBotToggleButton() {
    const [isChatBotOpen, setIsChatBotOpen] = useState(false);
    const theme = useTheme();
    const location = useLocation();

    const isSmallScreen = useMediaQuery(theme=>theme.breakpoints.down("sm"))

    const toggleChatBot = () => {
        setIsChatBotOpen((prev) => !prev);
    };

    
    useEffect(() => {
        setIsChatBotOpen(false);
    }, [location]);

    return (
        <Box>
            <Box
                sx={{
                    position: 'fixed',
                    bottom: {xs:'80px',md:'20px'},
                    right: '20px',
                    zIndex: 1000,
                }}
            >
                <IconButton
                    color="primary"
                    onClick={toggleChatBot}
                    sx={{
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.primary.contrastText,
                        '&:hover': {
                            backgroundColor: theme.palette.primary.dark,
                        },
                        color: "white",
                        boxShadow: 5
                    }}
                >
                    {
                        isChatBotOpen ? <CancelIcon /> : <ChatIcon />
                    }

                </IconButton>
            </Box>

            <Box
                style={{
                    position: 'fixed',
                    bottom: !isSmallScreen? (isChatBotOpen ? '80px' : '-500px' ):(isChatBotOpen ? "130px" : '-500px'),
                    right: '20px',
                    transition: 'bottom 0.3s ease-in-out',
                    zIndex: 1300,
                    height: "500px",
                    overflow: "hidden",
                    backgroundColor: theme.palette.background.paper,
                    boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
                    borderRadius: '8px',
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}
            >
            <ChatBot />
            </Box>
        </Box>
    )
}
