import React from 'react';
import { Box, Button, Typography, Stepper, Step, StepLabel } from '@mui/material';
import SecurityIcon from '@mui/icons-material/Security';
import Cart from './Cart';
// import AddressForm from './AddressForm';
// import PaymentForm from './PaymentForm';
import { useNavigate, useParams } from 'react-router-dom';
import Address from './Address';
import Payment from './Payment';

const steps = ['cart', 'address', 'payment'];

export default function CheckoutSteps() {
  const navigate = useNavigate();
  const { step } = useParams();
  const activeStep = steps.indexOf(step);

  const handleNext = () => {
    if (activeStep < steps.length - 1) {
      navigate(`/checkout/${steps[activeStep + 1]}`);
    }
  };

  const handleBack = () => {
    if (activeStep > 0) {
      navigate(`/checkout/${steps[activeStep - 1]}`);
    }
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 'cart':
        return <Cart />;
      case 'address':
        return <Address/>;
      case 'payment':
        return <Payment />;
      default:
        return null;
    }
  };

  return (
    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', my:4 }}>
        <Box sx={{width:"400px"}}>

      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label.toUpperCase()}</StepLabel>
          </Step>
        ))}
      </Stepper>
        </Box>
      <Box sx={{ width: '100%', mt: 4 }}>
        {renderStepContent(step)}
      </Box>
      {/* <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
        {activeStep !== 0 && <Button onClick={handleBack}>Back</Button>}
        {activeStep < steps.length - 1 ? (
          <Button size='small' sx={{color:"white"}} variant="contained" onClick={handleNext}>
            Next
          </Button>
        ) : (
          <Button size='small'  sx={{color:"white"}} variant="contained">Finish</Button>
        )}
      </Box> */}
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 3 }}>
        <SecurityIcon sx={{ color: 'green', mr: 1 }} />
        <Typography variant="subtitle1">100% SECURE</Typography>
      </Box>
    </Box>
  );
}
