import React, { useState } from 'react';
import {
    Box,
    Typography,
    Switch,
    TextField,
    Button,
    Divider,
    Grid,
    Snackbar,
    Alert,
} from '@mui/material';

export default function AccountPrivacy() {
    const [emailPreferences, setEmailPreferences] = useState(true);
    const [twoFactorAuth, setTwoFactorAuth] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'info',
    });

    const handleEmailPreferencesChange = (event) => {
        setEmailPreferences(event.target.checked);
        setSnackbar({
            open: true,
            message: `Email notifications ${
                event.target.checked ? 'enabled' : 'disabled'
            }.`,
            severity: 'success',
        });
    };

    const handleTwoFactorAuthChange = (event) => {
        setTwoFactorAuth(event.target.checked);
        setSnackbar({
            open: true,
            message: `Two-factor authentication ${
                event.target.checked ? 'enabled' : 'disabled'
            }.`,
            severity: 'success',
        });
    };

    const handlePasswordChange = () => {
        if (newPassword === confirmPassword) {
            setSnackbar({
                open: true,
                message: 'Password updated successfully!',
                severity: 'success',
            });
        } else {
            setSnackbar({
                open: true,
                message: 'Passwords do not match.',
                severity: 'error',
            });
        }
    };

    const handleAccountDeletion = () => {
        setSnackbar({
            open: true,
            message:
                'Account deletion initiated. You will be logged out shortly.',
            severity: 'warning',
        });
    };

    const handleCloseSnackbar = () => setSnackbar({ ...snackbar, open: false });

    return (
        <Box sx={{ p: 3 }}>
            <Typography variant="h6" gutterBottom>
                Account Privacy Settings
            </Typography>
            <Divider sx={{ mb: 2 }} />

            <Box sx={{ mb: 4 }}>
                <Typography variant="subtitle1" gutterBottom>
                    Email Preferences
                </Typography>
                <Typography variant="body2" color="textSecondary">
                    Receive email updates about your account activity.
                </Typography>
                <Switch
                    checked={emailPreferences}
                    onChange={handleEmailPreferencesChange}
                    color="primary"
                />
            </Box>

            <Divider sx={{ mb: 2 }} />

            <Box sx={{ mb: 4 }}>
                <Typography variant="subtitle1" gutterBottom>
                    Two-Factor Authentication
                </Typography>
                <Typography variant="body2" color="textSecondary">
                    Add an extra layer of security to your account by enabling
                    two-factor authentication.
                </Typography>
                <Switch
                    checked={twoFactorAuth}
                    onChange={handleTwoFactorAuthChange}
                    color="primary"
                />
            </Box>

            <Divider sx={{ mb: 2 }} />

            <Box sx={{ mb: 4 }}>
                <Typography variant="subtitle1" gutterBottom>
                    Change Password
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            label="New Password"
                            type="password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            label="Confirm Password"
                            type="password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                    </Grid>
                </Grid>
                <Button
                    variant="contained"
                    color="primary"
                    sx={{ mt: 2 }}
                    onClick={handlePasswordChange}
                    disabled={!newPassword || newPassword !== confirmPassword}
                >
                    Update Password
                </Button>
            </Box>

            <Divider sx={{ mb: 2 }} />

            <Box sx={{ mb: 3 }}>
                <Typography variant="subtitle1" gutterBottom color="error">
                    Delete Account
                </Typography>
                <Typography variant="body2" color="textSecondary">
                    Permanently delete your account and all associated data.
                </Typography>
                <Button
                    variant="outlined"
                    color="error"
                    sx={{ mt: 2 }}
                    onClick={handleAccountDeletion}
                    disabled
                >
                    Delete Account
                </Button>
            </Box>

            <Snackbar
                open={snackbar.open}
                autoHideDuration={3000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert
                    onClose={handleCloseSnackbar}
                    severity={snackbar.severity}
                    sx={{ width: '100%' }}
                >
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Box>
    );
}
