import React from 'react'
import Grid from "@mui/material/Grid"
import Card from "@mui/material/Card"
import Box from "@mui/material/Box"
import Typography from '@mui/material/Typography'
import Button from "@mui/material/Button"
import Stack from "@mui/material/Stack"
import Container from "@mui/material/Container"
import { Link } from 'react-router-dom';
import SectionTitle from '../core/SectionTitle'
import { useTheme } from "@mui/material/styles"
import useMediaQuery from '@mui/material/useMediaQuery'


const baseCloudinaryURL = process.env.REACT_APP_CLOUDINARY_BASE_URL;

const products = [
  { id: 1, title: 'Salad Veggies', img: `${baseCloudinaryURL}/w_600,h_400,c_fill,q_auto:low,f_auto/v1722407333/vegingo/card-img-veg_vnp5ip.jpg`,link:"/shop/salad veggies" },
  { id: 2, title: 'Stir-Fry Veggies', img: `${baseCloudinaryURL}/w_600,h_400,c_fill,q_auto:low,f_auto/v1726552720/vegingo/pexels-sebastian-coman-photography-1598188-3510155_qs7xzj.jpg`,link:"/shop/stir-fry veggies" },
  { id: 3, title: 'Indian Cooking', img: `${baseCloudinaryURL}/w_600,h_400,c_fill,q_auto:low,f_auto/v1726552834/vegingo/pexels-saveurssecretes-9266190_xunvv9.jpg`,link:"/shop/indian" },
  { id: 4, title: 'Chinese Cooking', img: `${baseCloudinaryURL}/w_600,h_400,c_fill,q_auto:low,f_auto/v1726554819/vegingo/pexels-pixabay-159357_hwprhj.jpg`,link:"/shop/chinese" },
  { id: 5, title: 'Soup Veggies', img: `${baseCloudinaryURL}/w_600,h_400,c_fill,q_auto:low,f_auto/v1726554640/vegingo/pexels-navada-ra-628779-1703272_wzw5di.jpg`,link:"/shop/soup veggies" },




];
export default function PopularCategory() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme=>theme.breakpoints.down('sm'));
  return (

    <Container maxWidth="lg" sx={{ mt: 5 }}>
      <SectionTitle t1="Popular Categories" t2="Explore Our Top Picks"/>



      <Box sx={{ display: "flex", justifyContent: "space-around", alignItems: "center", flexWrap: "wrap" }}>
        {products.map(product => (
          <Link key={product.id} to={product.link} style={{textAlign:"center"}}>
            <Box sx={{
              position: "relative", width:{xs:"100px",md: "200px"}, height: {xs:"100px",md:"200px"}, borderRadius: "50%", overflow: "hidden", "&:hover .category-img": {
                transform: "scale(1.2)"
              }
            }}>
              <img className="category-img" style={{ width: "100%", height: "100%", objectFit: "cover", transition: "transform 300ms ease-in-out" }} src={product.img} />

              {
               !isSmallScreen &&  <Box sx={{ position: "absolute", right: 5, bottom: 30, bgcolor: theme.palette.primary.main, p: 1, borderRadius: "1.2rem 0 0 1.2rem", color: "white", width: "130px", display: "flex", justifyContent: "center", boxShadow: 4 }}>
                {product.title}
              </Box>

              }
            


            </Box>

            {
              isSmallScreen && <Typography variant='caption' sx={{fontWeight:500}}>{product.title}</Typography>
              
            }

          </Link>
        ))}
      </Box>


    </Container>

  )
}
