import React, { useState } from 'react';
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import OrderItem from './OrderItem';
import { useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';
import Box from "@mui/material/Box"

export default function OrderList() {
    const { orderHistory } = useSelector(state => state.order);
    const [selectedOrder, setSelectedOrder] = useState(null);

    console.log("Order History: ", orderHistory)

    const handleViewDetails = (order) => {
        setSelectedOrder(order);
    };

    const handleCloseDetails = () => {
        setSelectedOrder(null);
    };

    return (
        <>
            {selectedOrder ? (

                <OrderItem
                    order={selectedOrder}
                    onClose={handleCloseDetails}
                    showDetails={true}
                />
            ) : (
                <>
                    {orderHistory.length > 0 ? (
                        <List>
                            {orderHistory.map((order, index) => (
                                <ListItem key={index} divider>
                                    <OrderItem
                                        order={order}
                                        onViewDetails={() => handleViewDetails(order)} // 
                                    />
                                </ListItem>
                            ))}
                        </List>
                    ) : (
                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                            <img width={300} src="/order-not-found.png" alt='order not found' />
                            <Typography variant="h5" color="textSecondary" align="center">
                                No orders found.
                            </Typography>
                        </Box>
                    )}
                </>
            )}
        </>
    );
}
