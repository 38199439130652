import React, { useState, useEffect } from 'react';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Box from '@mui/material/Box';
import { Link, useNavigate } from 'react-router-dom';
import Lock from '@mui/icons-material/Lock';
import Person from '@mui/icons-material/Person';
import Email from '@mui/icons-material/Email';
import Phone from '@mui/icons-material/Phone';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import { signup } from '../redux/slices/authSlice';
import PageTitle from '../components/core/PageTitle';
import { alpha, useTheme } from '@mui/material/styles';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import GoogleLoginButton from '../components/google-auth/GoogleLoginButton';
import Grid from '@mui/material/Grid';
import { debounce } from 'lodash';
import { Divider } from '@mui/material';

const Signup = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [mobileNumber, setMobileNumber] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [error, setError] = useState('');
    const [validationErrors, setValidationErrors] = useState({
        email: '',
        mobileNumber: '',
        password: '',
    });

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const theme = useTheme();

    const validateFields = () => {
        let errors = {};

        if (email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
            errors.email = 'Please enter a valid email address';
        }

        if (mobileNumber && !/^\d{10}$/.test(mobileNumber)) {
            errors.mobileNumber = 'Phone number format is invalid';
        }

        if (password && password.length < 7) {
            errors.password = 'Password must be at least 7 characters';
        }

        setValidationErrors(errors);
    };

    const debouncedValidateFields = debounce(validateFields, 1000);

    useEffect(() => {
        debouncedValidateFields();

        return () => debouncedValidateFields.cancel();
    }, [email, mobileNumber, password]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (Object.keys(validationErrors).length > 0) {
            return;
        }

        if (password !== confirmPassword) {
            setError('Passwords do not match');
            return;
        }
        try {
            const result = await dispatch(
                signup({ name, email, mobileNumber, password, confirmPassword })
            );
            console.log(result);

            if (signup.fulfilled.match(result)) {
                navigate("/");
            }
        } catch (err) {
            const errorMsg = err.message || 'Failed to sign up';
            setError(errorMsg);
        }
    };

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleClickShowConfirmPassword = () =>
        setShowConfirmPassword((show) => !show);

    const handleMobileNumberChange = (e) => {
        const value = e.target.value;
        if (/^\d*$/.test(value) && value.length <= 10) {
            setMobileNumber(value);
        }
    };

    return (
        <Box sx={{ minHeight: '80vh' }}>
            <Container sx={{ my: 5 }} maxWidth="lg">
                <Grid columnSpacing={2} rowGap={2} container>
                    <Grid item md={7} xs={12}>
                        <Card
                            sx={{
                                boxShadow: 'none',
                            }}
                        >
                            <CardContent>
                                <Typography
                                    variant="h4"
                                    align="center"
                                    gutterBottom
                                    fontFamily={"Playfair Display"}
                                    fontWeight={500}
                                >
                                    Sign up
                                </Typography>
                                <form onSubmit={handleSubmit}>
                                    <TextField
                                        size="small"
                                        label="Name"
                                        variant="outlined"
                                        fullWidth
                                        margin="normal"
                                        value={name}
                                        onChange={(e) =>
                                            setName(e.target.value)
                                        }
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Person />
                                                </InputAdornment>
                                            ),
                                        }}
                                        required
                                    />
                                    <TextField
                                        label="Email"
                                        size="small"
                                        variant="outlined"
                                        fullWidth
                                        margin="normal"
                                        value={email}
                                        onChange={(e) =>
                                            setEmail(e.target.value)
                                        }
                                        error={!!validationErrors.email}
                                        helperText={validationErrors.email}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Email />
                                                </InputAdornment>
                                            ),
                                        }}
                                        required
                                    />
                                    <TextField
                                        size="small"
                                        label="Mobile Number"
                                        type="tel"
                                        variant="outlined"
                                        fullWidth
                                        margin="normal"
                                        value={mobileNumber}
                                        onChange={handleMobileNumberChange}
                                        error={!!validationErrors.mobileNumber}
                                        helperText={
                                            validationErrors.mobileNumber
                                        }
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Phone />
                                                </InputAdornment>
                                            ),
                                        }}
                                        required
                                    />
                                    <TextField
                                        size="small"
                                        label="Password"
                                        type={
                                            showPassword ? 'text' : 'password'
                                        }
                                        variant="outlined"
                                        fullWidth
                                        margin="normal"
                                        value={password}
                                        onChange={(e) =>
                                            setPassword(e.target.value)
                                        }
                                        error={!!validationErrors.password}
                                        helperText={validationErrors.password}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Lock />
                                                </InputAdornment>
                                            ),
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={
                                                            handleClickShowPassword
                                                        }
                                                    >
                                                        {showPassword ? (
                                                            <VisibilityOff />
                                                        ) : (
                                                            <Visibility />
                                                        )}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                        required
                                    />
                                    <TextField
                                        size="small"
                                        label="Confirm Password"
                                        type={
                                            showConfirmPassword
                                                ? 'text'
                                                : 'password'
                                        }
                                        variant="outlined"
                                        fullWidth
                                        margin="normal"
                                        value={confirmPassword}
                                        onChange={(e) =>
                                            setConfirmPassword(e.target.value)
                                        }
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Lock />
                                                </InputAdornment>
                                            ),
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={
                                                            handleClickShowConfirmPassword
                                                        }
                                                    >
                                                        {showConfirmPassword ? (
                                                            <VisibilityOff />
                                                        ) : (
                                                            <Visibility />
                                                        )}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                        required
                                    />
                                    {error && (
                                        <Typography
                                            color="error"
                                            variant="body2"
                                            align="center"
                                        >
                                            {error}
                                        </Typography>
                                    )}
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            my: 2,
                                        }}
                                    >
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            sx={{
                                                backgroundColor:
                                                    theme.palette.primary.main,

                                                color: 'white',
                                            }}
                                        >
                                            SIGN UP
                                        </Button>
                                    </Box>
                                </form>
                                <Divider flexItem>OR</Divider>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        mt: 2,
                                    }}
                                >
                                
                                    <GoogleLoginButton />
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid
                        item
                        md={5}
                        xs={12}
                    >
                          <Box sx={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center",backgroundColor:theme.palette.primary.main }}>
                            <Box sx={{textAlign:"center",p:4}}>
                                <Typography color={"white"} gutterBottom fontFamily={"Playfair Display"} fontWeight={500} variant='h4'>Already have an account ?</Typography>
                                <Typography gutterBottom variant='body1' color="white">
                                Log in and continue exploring amazing opportunities!
                                </Typography>
                                
                                <Link to={"/account/login"}>
    <Button variant='outlined' sx={{ borderColor: "white", mt: 2, color: "white", "&:hover":{
        backgroundColor:"white", color:"black"
    } }}>Log In</Button>
</Link>

                            </Box>



                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default Signup;
