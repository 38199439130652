import axios from 'axios';
import authService from './authService';
import api from '../apiCollection';

const getAuthToken = () => {
    const user = authService.getCurrentUser();
    const token = user ? user.token : null;
    // console.log("AuthToken:", token);
    return token;
};

const getWishlistItems = async () => {
    try {
        const token = getAuthToken();
        // console.log("Token: ", token);
        const response = await axios.get(`${api.wishlist.getAll}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        // console.log("getWishlistItems response:", response);
        return response.data;
    } catch (error) {
        console.error('Error getting wishlist items:', error);
        throw error;
    }
};

const addItemToWishlist = async (id) => {
    try {
        const token = getAuthToken();
        const formData = new FormData();
        formData.append('product_id', id);
        const response = await axios.post(`${api.wishlist.addItem}`, formData, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data',
            },
        });
        console.log('addItemToWishlist response:', response);
        return response.data;
    } catch (error) {
        console.error('Error adding item to wishlist:', error);
        throw error;
    }
};

const deleteWishlistItem = async (itemId) => {
    try {
        const token = getAuthToken();
        await axios.delete(`${api.wishlist.deleteItem}/${itemId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        console.log('deleteWishlistItem response: Item deleted');
        return itemId;
    } catch (error) {
        console.error('Error deleting wishlist item:', error);
        throw error;
    }
};

export default {
    getWishlistItems,
    addItemToWishlist,
    deleteWishlistItem,
};
