import React from "react";
import { Button, Box } from "@mui/material";

const Options = (props) => {
  const options = [
    { text: "Order Status", handler: props.actionProvider.handleOrderStatus },
    { text: "Shipping Info", handler: props.actionProvider.handleShipping },
    { text: "Return Policy", handler: props.actionProvider.handleReturnPolicy },
  ];

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
      {options.map((option, index) => (
        <Button
        size="small"
          key={index}
          variant="contained"
          onClick={option.handler}
          sx={{ textTransform: "none",color:"white" }}
        >
          {option.text}
        </Button>
      ))}
    </Box>
  );
};

export default Options;
