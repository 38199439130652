import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { googleLogin } from '../../redux/slices/authSlice';
import Loading from '../core/Loading';


const GoogleCallback = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch()

  useEffect(() => {
    const handleCallback = async () => {
      const params = new URLSearchParams(location.search);


      if (params.has('code')) {
        const code = params.get('code');
        console.log(code);
        try {
          const result = await dispatch(googleLogin(code)).unwrap();
          console.log('Google login successful:', result);
          navigate("/");
        } catch (error) {
          console.error('Google login failed:', error);
        }
      }
  
    };

    handleCallback();
  }, [location, navigate]);

  return <Loading/>;
};

export default GoogleCallback;

