class ActionProvider {
  constructor(createChatBotMessage, setStateFunc) {
    this.createChatBotMessage = createChatBotMessage;
    this.setState = setStateFunc;
  }

  handleHello = () => {
    const message = this.createChatBotMessage("Hello! How can I assist you today?", {
      widget: "options"
    });
    
    this.setState((prev) => ({
      ...prev,
      messages: [...prev.messages, message],
    }));
  };

  handleOrderStatus = () => {
    const message = this.createChatBotMessage("You can check your order status by visiting your account page or providing your order ID.");
    this.setState((prev) => ({
      ...prev,
      messages: [...prev.messages, message],
    }));
  };

  handleShipping = () => {
    const message = this.createChatBotMessage("Shipping usually takes 3-5 business days. Free shipping is available on orders above $50.");
    this.setState((prev) => ({
      ...prev,
      messages: [...prev.messages, message],
    }));
  };

  handleReturnPolicy = () => {
    const message = this.createChatBotMessage("We have a 30-day return policy. You can initiate a return from your account or contact our support team.");
    this.setState((prev) => ({
      ...prev,
      messages: [...prev.messages, message],
    }));
  };

  handlePaymentOptions = () => {
    const message = this.createChatBotMessage("We accept credit cards, debit cards, PayPal, and Cash on Delivery.");
    this.setState((prev) => ({
      ...prev,
      messages: [...prev.messages, message],
    }));
  };

  handleRefund = () => {
    const message = this.createChatBotMessage("Refunds are processed within 5-7 business days after we receive the returned item.");
    this.setState((prev) => ({
      ...prev,
      messages: [...prev.messages, message],
    }));
  };

  handleDiscounts = () => {
    const message = this.createChatBotMessage("You can apply discount codes during checkout. Be sure to check our latest promotions on the homepage.");
    this.setState((prev) => ({
      ...prev,
      messages: [...prev.messages, message],
    }));
  };

  handleCustomerSupport = () => {
    const message = this.createChatBotMessage("For customer support, you can reach out to us via email at support@ecommerce.com or call us at 1-800-123-456.");
    this.setState((prev) => ({
      ...prev,
      messages: [...prev.messages, message],
    }));
  };

  handleUnknown = () => {
    const message = this.createChatBotMessage("I'm sorry, I didn't understand that. Can you please rephrase your question?");
    this.setState((prev) => ({
      ...prev,
      messages: [...prev.messages, message],
    }));
  };
}

export default ActionProvider;
