import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import AddIcon from '@mui/icons-material/Add';
import AddressItem from './AddressItem';
import AddressForm from './AddressForm';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Divider, useMediaQuery } from '@mui/material';
import Typography from '@mui/material/Typography';

export default function AddressList({ enableRadio = false }) {

    const [isAddingAddress, setIsAddingAddress] = useState(false);
    const [editingAddress, setEditingAddress] = useState(null);
    const [selectedAddressId, setSelectedAddressId] = useState(null);
    const address = useSelector(state => state.address);
    const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));

    const handleAddAddressClick = () => {
        setEditingAddress(null);
        setIsAddingAddress(true);
    };

    const handleSaveAddress = () => {
        setIsAddingAddress(false);
        setEditingAddress(null);
    };

    const handleEditAddress = (address) => {
        setEditingAddress(address);
        setIsAddingAddress(true);
    };

    const handleCloseForm = () => {
        setIsAddingAddress(false);
        setEditingAddress(null);
    };

    const handleSelectAddress = (id) => {
        setSelectedAddressId(id);
    };

    const defaultAddress = address.addresses.filter(item => item.is_default === 1);
    const otherAddresses = address.addresses.filter(item => item.is_default === 0);

    return (
        isAddingAddress ? (
            <AddressForm
                onSave={handleSaveAddress}
                onClose={handleCloseForm}
                editingAddress={editingAddress}
            />
        ) : (
            <>
                {address.addresses.length === 0 ? (
                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                        <img width={400} src="/address-not-found.webp" alt='address not found' />
                        <Typography gutterBottom variant="h5" color="text.secondary">No saved addresses</Typography>
                        <Button
                            sx={{ borderRadius: "1.2rem", color: "white" }}
                            variant="contained"
                            size='small'
                            startIcon={<AddIcon />}
                            onClick={handleAddAddressClick}>
                            Add address
                        </Button>
                    </Box>
                ) : (
                    <>
                        <Typography gutterBottom variant='body1' fontWeight={600}>Default Address</Typography>
                        {defaultAddress.map((address) => (
                            <AddressItem
                                key={address.id}
                                address={address}
                                onEdit={handleEditAddress}
                                enableRadio={enableRadio}
                                selectedAddressId={selectedAddressId}
                                onSelect={handleSelectAddress}
                            />
                        ))}

                        {otherAddresses.length > 0 && (
                            <>
                                <Divider flexItem sx={{ my: 2 }} />
                                <Typography fontWeight={600} gutterBottom variant='body1'>Other Addresses</Typography>
                                {otherAddresses.map((address) => (
                                    <AddressItem
                                        key={address.id}
                                        address={address}
                                        onEdit={handleEditAddress}
                                        enableRadio={enableRadio}
                                        selectedAddressId={selectedAddressId}
                                        onSelect={handleSelectAddress}
                                    />
                                ))}
                            </>
                        )}
                        
                        <Box display="flex" my={2} flexDirection={isSmallScreen ? "column" : "row"}
                            gap={2}
                            justifyContent="center" alignItems="center">
                            <Button
                                sx={{ borderRadius: "1.2rem", color: "white" }}
                                variant="contained"
                                size='small'
                                startIcon={<AddIcon />}
                                onClick={handleAddAddressClick}>
                                Add new address
                            </Button>
                        </Box>
                    </>
                )}
            </>
        )
    );
}
