import { useState, useEffect } from 'react';
import axios from 'axios';

const useAllData = (initialUrl, initialPage = 1, productId = null, categoryId = null) => {
    const [allData, setAllData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const fetchAllData = async () => {
        setLoading(true);
        setError(null);
        try {
            let allFetchedData = [];
            let page = initialPage;
            let totalPagesFetched = 1;
            while (page <= totalPagesFetched) {

                let url = `${initialUrl}?page=${page}`;
                if (productId) {
                    url += `&product_id=${productId}`;
                }
                if (categoryId) {
                    url += `&category_id=${categoryId}`;
                }

                const response = await axios.get(url);
                allFetchedData = [...allFetchedData, ...response.data.data.products];
                totalPagesFetched = response.data.data.pagination.total_pages;
                page++;
            }
            setAllData(allFetchedData);
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchAllData();
    }, []);

    return { allData, loading, error };
};

export default useAllData;
