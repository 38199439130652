import React, { useEffect } from 'react'
import Hero from '../components/home/Hero';
import Testimonials from '../components/home/Testimonials';
import SpecialProducts from '../components/home/SpecialProducts';
import LatestNews from '../components/home/LatestNews';
import useAllData from '../hooks/useAllData';
import Loading from '../components/core/Loading';
import api from '../apiCollection';
import Error from '../components/core/Error';
import FreshFoodBanner from "../components/home/FreshFoodBanner"
import OurProducts from '../components/home/OurProducts';
import NewProducts from '../components/home/NewProducts';
import BestSeller from '../components/home/BestSeller';
import FarmToHomeBanner from '../components/home/FarmToHomeBanner';
import useMediaQuery from '@mui/material/useMediaQuery'
import InstallApp from '../components/home/InstallApp';
import { useDispatch, useSelector } from 'react-redux';
import { mergeCart } from '../redux/slices/cartSlice';
import FlashDeals from '../components/home/FlashDeals';
import PopularCategory from '../components/home/PopularCategory';


export default function Home() {
  const { allData, loading, error } = useAllData(api.products.getAll);
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));

  
  
  const specialProducts = allData.filter(product => product.best_seller);

  const dispatch = useDispatch();
  const {isAuthenticated} = useSelector(state=>state.auth);
  

  
  useEffect(()=>{
    if(isAuthenticated){
      dispatch(mergeCart());
    }
  },[isAuthenticated]);
  
  
  
  
    if (loading) {
      return <Loading />;
    }
  
    if (error) {
      console.log(error)
      return <Error code={error.request.status} />;
    }


  return (
    <>
      <Hero />
      <PopularCategory/>
      <FlashDeals/>
      <NewProducts allProducts={allData} />
      <BestSeller allProducts={allData} />
      {
        !isSmallScreen && <FarmToHomeBanner />
      }

      <OurProducts allProducts={allData} />
      {/* <SpecialDiscountBanner/> */}
      <FreshFoodBanner />
      <SpecialProducts specialProducts={allData} />
      <Testimonials />
      <LatestNews />
      <InstallApp />
      {/* <ProductPolicy /> */}

    </>
  )
}
