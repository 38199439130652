import React, { useState, useEffect } from 'react';
import {
    Typography,
    Box,
    Card,
    CardMedia,
    Button,
    CircularProgress,
    CardContent,
    Popover,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import QuantitySelector from '../cart/QuantitySelector';
import { useDispatch, useSelector } from 'react-redux';
import {
    addItemToLocalCart,
    addItemToServerCart,
    fetchLocalCart,
    fetchServerCart,
} from '../../redux/slices/cartSlice';
import ProductModal from './ProductModal';

const ProductCard = ({
    productData,
    flexDirection = 'column',
    button = true,
}) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const [isLoading, setIsLoading] = useState(false);
    const [detailOpen, setDetailOpen] = useState(null);
    const [timeRemaining, setTimeRemaining] = useState('');
    const [modalOpen, setModalOpen] = useState(false);

    const { isAuthenticated } = useSelector((state) => state.auth);
    const localCart = useSelector((state) => state.cart.localCart);
    const serverCart = useSelector((state) => state.cart.serverCart);
    const cartItems = isAuthenticated
        ? serverCart.products
        : localCart.products;
    const isProductInCart = cartItems.some(
        (item) => item.product.id === productData.id
    );

    // Offer calculation
    const originalPrice = Math.round(Number(productData.price) || 0);
    const discountedPrice = Math.round(
        Number(productData.discounted_price) || 0
    );
    const offer = productData.offer_details;

    let finalPrice = originalPrice;
    let offerPrice = 0;
    if (offer) {
        offerPrice =
            offer?.discount_type === 'FLAT'
                ? Math.max(
                      0,
                      discountedPrice - Math.round(offer.discount_value)
                  )
                : Math.max(
                      0,
                      Math.round(
                          discountedPrice * (1 - offer.discount_value / 100)
                      )
                  );
    }

    const handleAddItem = async () => {
        setIsLoading(true);
        try {
            if (isAuthenticated) {
                await dispatch(addItemToServerCart(productData)).unwrap();
                dispatch(fetchServerCart());
            } else {
                dispatch(addItemToLocalCart(productData));
                dispatch(fetchLocalCart());
            }
        } catch (error) {
            console.error('Error adding item to cart:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleImageClick = () => {
        setModalOpen((prev) => !prev);
    };

    useEffect(() => {
        if (!offer || !detailOpen) return;
        const interval = setInterval(() => {
            const now = new Date();
            const endDate = new Date(offer.end_date);
            const diff = endDate - now;

            if (diff <= 0) {
                setTimeRemaining('Expired');
                clearInterval(interval);
            } else {
                const hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
                const minutes = Math.floor((diff / (1000 * 60)) % 60);
                const seconds = Math.floor((diff / 1000) % 60);
                setTimeRemaining(`${hours}h ${minutes}m ${seconds}s`);
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [offer, detailOpen]);

    return (
        <Box sx={{ position: 'relative', width: '100%', height: '100%' }}>
            <Card
                elevation={2}
                sx={{
                    display: 'flex',
                    flexDirection: flexDirection,
                    width: '100%',
                    height: '100%',
                    '&:hover .product-image': {
                        transform: 'scale(1.1)',
                    },
                }}
            >
                <Box
                    height={flexDirection === 'row' ? '100%' : '150px'}
                    width={flexDirection === 'row' ? '300px' : '100%'}
                    sx={{
                        position: 'relative',
                        cursor: 'pointer',
                        overflow: 'hidden',
                    }}
                >
                    <CardMedia
                        onClick={handleImageClick}
                        className="product-image"
                        component="img"
                        loading="lazy"
                        image={productData.image || '/default.png'}
                        alt={productData.name || 'Product'}
                        sx={{
                            objectFit: 'cover',
                            transition: 'transform 0.3s ease-in-out',
                            width: '100%',
                            height: '100%',
                        }}
                    />

                    <Box
                        sx={{
                            p: 1,
                            bgcolor: 'orange',
                            color: 'white',
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            fontSize: '0.8rem',
                            boxShadow: 5,
                        }}
                    >
                        {Math.round(
                            ((originalPrice - discountedPrice) /
                                originalPrice) *
                                100
                        )}
                        % OFF
                    </Box>
                </Box>

                <CardContent
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        flexGrow: 1,
                    }}
                >
                    <Box
                        sx={{
                            textAlign: 'center',
                            width: '100%',
                            textAlign:
                                flexDirection === 'column' ? 'center' : 'left',
                        }}
                    >
                        <Typography
                            variant="body2"
                            fontWeight="600"
                            component="div"
                            sx={{ color: 'gray' }}
                        >
                            {productData.name || 'Product Name'}
                        </Typography>
                        {flexDirection === 'row' && (
                            <Typography variant="body2">
                                {productData.description}
                            </Typography>
                        )}

                        <Box
                            sx={{
                                display: 'flex',
                                gap: 2,
                                alignItems: 'center',
                                justifyContent:
                                    flexDirection === 'column'
                                        ? 'center'
                                        : 'left',
                            }}
                        >
                            <Typography
                                variant="body2"
                                sx={{
                                    textDecoration:
                                        finalPrice < originalPrice ||
                                        discountedPrice > 0
                                            ? 'line-through'
                                            : 'none',
                                    color:
                                        finalPrice < originalPrice ||
                                        discountedPrice > 0
                                            ? 'gray'
                                            : theme.palette.primary.main,
                                }}
                            >
                                ₹ {originalPrice}
                            </Typography>

                            <Typography
                                variant="h7"
                                fontWeight="600"
                                color={theme.palette.primary.main}
                            >
                                ₹ {discountedPrice}
                            </Typography>
                        </Box>
                        {offer && (
                            <Box
                                sx={{ display: 'inline-block', width: '100%' }}
                            >
                                <Button
                                    fullWidth
                                    onClick={(event) =>
                                        setDetailOpen(event.currentTarget)
                                    }
                                    size="small"
                                    variant="outlined"
                                    color="warning"
                                    sx={{
                                        my: 1,
                                        textTransform: 'none',
                                        background:
                                            'linear-gradient(to right, white, #FFCDD2)',
                                        '&:hover': {
                                            background:
                                                'linear-gradient(to right, white, #FFCDD2)',
                                        },
                                    }}
                                >
                                    <ShoppingBagIcon
                                        sx={{ fontSize: '1rem', mb: 0.3 }}
                                    />
                                    Get it for ₹{offerPrice}
                                </Button>
                                <Popover
                                    open={Boolean(detailOpen)}
                                    anchorEl={detailOpen}
                                    onClose={() => setDetailOpen(null)}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    }}
                                    sx={{
                                        '& .MuiPopover-paper': {
                                            borderRadius: '8px',
                                            boxShadow:
                                                '0px 4px 12px rgba(0, 0, 0, 0.15)',
                                            backgroundColor: '#fff',
                                        },
                                    }}
                                >
                                    <Box
                                        sx={{
                                            p: 2,
                                            width: '250px',
                                            textAlign: 'center',
                                        }}
                                    >
                                        <IconButton
                                            onClick={() => setDetailOpen(null)}
                                            sx={{
                                                position: 'absolute',
                                                top: '8px',
                                                right: '8px',
                                                color: '#FF4500',
                                                
                                            }}
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                        <Typography
                                            variant="h6"
                                            sx={{
                                                fontWeight: 'bold',
                                                color: '#FF4500',
                                                mt:2,
                                                marginBottom: '8px',
                                                
                                            }}
                                        >
                                            {offer.name}
                                        </Typography>
                                        <Typography
                                            color="text.primary"
                                            variant="body2"
                                            sx={{
                                                fontWeight: 'bold',
                                                color: '#333',
                                            }}
                                        >
                                            {offer.description}
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontSize: '0.6rem',
                                                color: '#333',
                                                mt: 0.5,
                                            }}
                                        >
                                            (Automatically Discounted at
                                            checkout)
                                        </Typography>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                mt: 2,
                                                p: 1,
                                                backgroundColor: '#FFF3E0',
                                                borderRadius: '4px',
                                            }}
                                        >
                                            <AccessTimeIcon
                                                sx={{
                                                    fontSize: '1rem',
                                                    color: '#FF8C00',
                                                    mr: 0.5,
                                                }}
                                            />
                                            <Typography
                                                color="text.secondary"
                                                variant="body2"
                                                sx={{
                                                    fontSize: '0.875rem',
                                                    fontWeight: 'bold',
                                                    color: '#FF8C00',
                                                }}
                                            >
                                                Expires in: {timeRemaining}
                                            </Typography>
                                        </Box>

                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                mt: 1,
                                                p: 1,
                                                backgroundColor: '#E8F5E9',
                                                borderRadius: '4px',
                                            }}
                                        >
                                            <CalendarTodayIcon
                                                sx={{
                                                    fontSize: '1rem',
                                                    color: '#388E3C',
                                                    mr: 0.5,
                                                }}
                                            />
                                            <Typography
                                                color="text.secondary"
                                                variant="body2"
                                                sx={{
                                                    fontSize: '0.875rem',
                                                    color: '#388E3C',
                                                }}
                                            >
                                                Valid till{' '}
                                                {new Date(
                                                    offer.end_date
                                                ).toLocaleDateString()}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Popover>
                            </Box>
                        )}
                    </Box>

                    {button &&
                        (isProductInCart ? (
                            <QuantitySelector
                                item={cartItems.find(
                                    (item) => item.product.id === productData.id
                                )}
                            />
                        ) : (
                            <Button
                                onClick={handleAddItem}
                                size="small"
                                variant="contained"
                                disabled={
                                    productData.stock_quantity === 0 ||
                                    isLoading
                                }
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 0.5,
                                    color: 'white',
                                }}
                            >
                                {!isLoading ? (
                                    <>
                                        <Typography variant="caption">
                                            Add to cart
                                        </Typography>
                                        <ShoppingBagIcon
                                            sx={{ fontSize: '1rem', mb: 0.3 }}
                                        />
                                    </>
                                ) : (
                                    <CircularProgress
                                        sx={{ color: 'white' }}
                                        size={20}
                                    />
                                )}
                            </Button>
                        ))}
                </CardContent>

                {productData.stock_quantity === 0 && (
                    <Box
                        sx={{
                            p: 1,
                            bgcolor: 'red',
                            color: 'white',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            fontSize: '0.8rem',
                            boxShadow: 5,
                        }}
                    >
                        Sold Out
                    </Box>
                )}
            </Card>

            <ProductModal
                open={modalOpen}
                handleClose={handleImageClick}
                productData={productData}
            />
        </Box>
    );
};

export default ProductCard;
