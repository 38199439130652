import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import Rating from '@mui/material/Rating';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from 'react-redux';
import axios from 'axios';
import api from '../../apiCollection';
import authService from '../../services/authService';
import { toast } from 'react-toastify';
import Error from '../../components/core/Error';

const getAuthToken = () => {
    const user = authService.getCurrentUser();
    return user ? user.token : null;
};

export default function RatingReview({ productId }) {
    const { user } = useSelector((state) => state.auth);
    const [reviews, setReviews] = useState([]);
    const [open, setOpen] = useState(false);
    const [newReview, setNewReview] = useState({
        rating: 0,
        review: '',
        user: user.name,
        date: '',
        images: [],
    });
    const [userHasReviewed, setUserHasReviewed] = useState(false);

    useEffect(() => {
        const userHasReviewed = localStorage.getItem(`reviewed_${productId}`);
        setUserHasReviewed(userHasReviewed === 'true');
    }, [productId]);

    useEffect(() => {
        async function fetchProductAndReviews() {
            try {
                const response = await axios.get(
                    `${api.products.getById}/${productId}`
                );
                const { data } = response.data;
                setReviews(data.reviews || []);
                console.log(data.reviews);
            } catch (error) {
                console.error('Error fetching product and reviews:', error);
            }
        }
        fetchProductAndReviews();
    }, [productId]);

    const averageRating =
        reviews.length > 0
            ? reviews.reduce((acc, curr) => acc + curr.rating, 0) /
              reviews.length
            : 0;

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        setNewReview({
            rating: 0,
            review: '',
            user: user.name,
            date: '',
            images: [],
        });
    };

    const handleSubmit = async () => {
        const currentDate = new Date();
        const formattedDate = `${currentDate.toLocaleDateString('en-GB', {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
        })}, ${currentDate.toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
        })}`;

        try {
            const token = getAuthToken();
            if (!token) {
                console.error('User is not authenticated');
                return;
            }

            const formData = new FormData();
            formData.append('rating', newReview.rating);
            formData.append('review', newReview.review);
            formData.append('date', formattedDate);
            newReview.images.forEach((image, index) => {
                formData.append(`images[${index}]`, image);
            });

            await axios.post(
                `${api.reviews.addReview}/${productId}`,
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );

            setReviews([...reviews, { ...newReview, date: formattedDate }]);
            setUserHasReviewed(true);
            localStorage.setItem(`reviewed_${productId}`, 'true');
            handleClose();
        } catch (error) {
            toast.error(error.response.data.error, {
                position: 'top-center',
            });
            return <Error code={error.request.status} />;
            // console.error('Error adding review:', error.response.data.error);
        }
    };

    const handleEdit = (review) => {
        setNewReview({
            id: review.id,
            rating: review.rating,
            review: review.review,
            images: review.images || [], // image logic is yet to be implemented in edit review
        });
        setOpen(true);
    };

    const handleUpdate = async () => {
        console.log('Updating review with:', newReview);
        try {
            const token = getAuthToken();
            if (!token) {
                console.error('User is not authenticated');
                return;
            }

            const updatedReviewData = {
                rating: newReview.rating,
                review: newReview.review,
                images: newReview.images,
            };

            await axios.put(
                `${api.reviews.updateReview}/${newReview.id}`,
                updatedReviewData,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                }
            );

            setReviews(
                reviews.map((r) =>
                    r.id === newReview.id ? { ...newReview } : r
                )
            );

            handleClose();
        } catch (error) {
            console.error(
                'Error updating review:',
                error.response ? error.response.data : error.message
            );
        }
    };

    const handleDelete = async (reviewId) => {
        try {
            const token = getAuthToken();
            if (!token) {
                console.error('User is not authenticated');
                return;
            }

            await axios.delete(`${api.reviews.deleteReview}/${reviewId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            setReviews(reviews.filter((r) => r.id !== reviewId));
            if (reviews.length === 1) {
                localStorage.removeItem(`reviewed_${productId}`);
            }
        } catch (error) {
            console.error('Error deleting review:', error);
        }
    };

    return (
        <Box sx={{ width: '100%', p: 3 }}>
            <Typography variant="h5" fontWeight="bold" gutterBottom>
                RATINGS
            </Typography>

            <Grid container spacing={4}>
                <Grid item xs={12} md={4}>
                    <Box sx={{ textAlign: 'center' }}>
                        <Typography variant="h4">
                            {averageRating.toFixed(1)}
                        </Typography>
                        <Rating
                            value={averageRating}
                            precision={0.1}
                            readOnly
                            size="large"
                        />
                        <Typography variant="body2" color="textSecondary">
                            {reviews.length} Reviews
                        </Typography>
                    </Box>
                </Grid>

                <Grid item xs={12} md={8}>
                    {[5, 4, 3, 2, 1].map((star) => (
                        <Box
                            key={star}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                mb: 1,
                            }}
                        >
                            <Typography variant="body1">{star}</Typography>
                            <Rating value={star} readOnly size="small" />
                            <Typography variant="body2" sx={{ ml: 1 }}>
                                {
                                    reviews.filter(
                                        (review) => review.rating === star
                                    ).length
                                }
                            </Typography>
                        </Box>
                    ))}
                </Grid>
            </Grid>

            <Divider sx={{ my: 3 }} />

            <Typography variant="h6" gutterBottom>
                Customer Reviews ({reviews.length})
            </Typography>

            <Grid container spacing={3}>
                {reviews.map((review) => (
                    <Grid item xs={12} md={6} key={review.id}>
                        <Card
                            variant="outlined"
                            sx={{ boxShadow: 3, borderRadius: 2 }}
                        >
                            <CardContent>
                                <Rating value={review.rating} readOnly />
                                <Typography variant="body1" gutterBottom>
                                    {review.review}
                                </Typography>
                                <Typography variant="caption">
                                    {review.user.name} |{' '}
                                    {new Date(
                                        review.updated_at
                                    ).toLocaleDateString()}
                                </Typography>

                                <Box sx={{ display: 'flex', gap: 1, mt: 2 }}>
                                    {review.images.map((img, idx) => (
                                        <Avatar
                                            key={idx}
                                            src={img.image_path}
                                            variant="rounded"
                                            sx={{ width: 80, height: 80 }}
                                        />
                                    ))}
                                </Box>
                            </CardContent>
                            <CardActions>
                                {user.id === review.user_id && (
                                    <>
                                        <Button
                                            size="small"
                                            color="primary"
                                            onClick={() => handleEdit(review)}
                                        >
                                            Edit
                                        </Button>

                                        <Button
                                            size="small"
                                            color="error"
                                            onClick={() =>
                                                handleDelete(review.id)
                                            }
                                        >
                                            Delete
                                        </Button>
                                    </>
                                )}
                            </CardActions>
                        </Card>
                    </Grid>
                ))}
            </Grid>

            <Button
                variant="contained"
                color="primary"
                onClick={handleOpen}
                disabled={userHasReviewed}
                sx={{ mt: 3, borderRadius: 3 }}
            >
                {userHasReviewed
                    ? 'You have already reviewed this product'
                    : 'Write a Review'}
            </Button>

            <Modal open={open} onClose={handleClose}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        borderRadius: 2,
                    }}
                >
                    <IconButton
                        onClick={handleClose}
                        sx={{ position: 'absolute', top: 8, right: 8 }}
                    >
                        <CloseIcon />
                    </IconButton>

                    <Typography variant="h6" gutterBottom>
                        Add/Edit Review
                    </Typography>

                    <FormControl fullWidth sx={{ mb: 2 }}>
                        <Rating
                            name="rating"
                            value={newReview.rating}
                            onChange={(e, val) =>
                                setNewReview({ ...newReview, rating: val })
                            }
                            size="large"
                        />
                    </FormControl>

                    <TextField
                        fullWidth
                        label="Review"
                        name="review"
                        value={newReview.review}
                        onChange={(e) =>
                            setNewReview({
                                ...newReview,
                                review: e.target.value,
                            })
                        }
                        multiline
                        rows={4}
                        sx={{ mb: 2 }}
                    />

                    <Button
                        sx={{ width: '100%', mb: 2, borderRadius: 2 }}
                        variant="contained"
                        component="label"
                        color="primary"
                    >
                        Upload Images
                        <input
                            type="file"
                            hidden
                            multiple
                            onChange={(e) =>
                                setNewReview({
                                    ...newReview,
                                    images: [...e.target.files],
                                })
                            }
                        />
                    </Button>

                    <Box sx={{ display: 'flex', gap: 1 }}>
                        {newReview.images.map((img, index) => (
                            <Avatar
                                key={index}
                                src={
                                    typeof img === 'string'
                                        ? img
                                        : URL.createObjectURL(img)
                                }
                                variant="rounded"
                                sx={{ width: 80, height: 80 }}
                            />
                        ))}
                    </Box>

                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={newReview.id ? handleUpdate : handleSubmit}
                        sx={{ mt: 3, borderRadius: 2 }}
                    >
                        {newReview.id ? 'Update Review' : 'Submit Review'}
                    </Button>
                </Box>
            </Modal>
        </Box>
    );
}
