import React from 'react'
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Grid"
import useAllData from "../../hooks/useAllData"
import api from '../../apiCollection'
import ProductCard from "../product/ProductCard"

export default function SimilarProducts() {
    // should be later filtered according to the tags of the products in the cart
    const {loading, error,allData} = useAllData(api.products.getAll);

    if(loading){
        <p>Loading....</p>
    }

    if(error){
        <p>{error.message}</p>
    }
  return ( 
    <Box sx={{ mt: 4 }}>
    <Typography variant="h6" sx={{fontWeight:600}}>You may also like these products</Typography>
    <Grid container columnSpacing={2} rowGap={5} sx={{ my: 2 }}>

        {
            allData.slice(0,4).map((product)=>(
                <Grid key={product.id} item xs={6} md={3}>
                <ProductCard  productData={product}/>
                </Grid>
            ))

        }
        

    </Grid>
  </Box>
  )
}
