import React from 'react'
import Box from "@mui/material/Box"
import { Container } from '@mui/material';

const baseCloudinaryURL = process.env.REACT_APP_CLOUDINARY_BASE_URL;

const bgIMG = `${baseCloudinaryURL}/v1726942089/vegingo/Copy_of_Order_now_cpbieg.png`
export default function FarmToHomeBanner() {
    
  return (
    <Container maxWidth={"lg"}>
<Box sx={{height:"300px", width:"100%",mt:10}}>
        <img src={bgIMG} style={{width:"100%",height:"300px",objectFit:"cover", maxHeight:"60vh", borderRadius:4}}/>
    </Box>
    </Container>
    
  )
}
