import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import { Autoplay, Pagination } from 'swiper/modules';
import Box from "@mui/material/Box";
import useAllData from '../../hooks/useAllData';
import api from '../../apiCollection';
import Error from '../core/Error';
import FlashDealCard from './FlashDealCard';
import { useTheme } from "@mui/material/styles";
import useMediaQuery from '@mui/material/useMediaQuery';
import { Container, Typography } from '@mui/material';
import CountdownTimer from '../product/CountdownTimer';
import ProductCard from '../product/ProductCard';

const baseCloudinaryURL = process.env.REACT_APP_CLOUDINARY_BASE_URL;
const bgImageURL = `${baseCloudinaryURL}/v1729676497/vegingo/f7f7f7_3_pio8fb.png`;

export default function FlashDeals() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm')); 
  const { allData, loading, error } = useAllData(api.products.getAll);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <Error code={error.request.status} />;
  }

  return (
    <Box sx={{
  background:"background.paper",
  background:`url(${bgImageURL})`,
  backgroundPosition:"center",
  backgroundSize:"cover",
      py: 5,
      mt: 10
    }}>
      <Container maxWidth="lg">
        
      <Typography variant={isSmallScreen?'h5':'h4'}  sx={{fontFamily:"Playfair Display", fontWeight:600, textAlign:"center", color:"black"}} >Deals Of The Day</Typography>
      <CountdownTimer days={7}/>
        <Box sx={{ position: 'relative', mt:4,height:"250px" }}>
          <Swiper
            autoplay={{ delay: 3000 }}
            slidesPerView={isSmallScreen ? 1 : 2}
            spaceBetween={40}
            pagination={{ clickable: true }}
            modules={[Autoplay, Pagination]}
            className="mySwiper3"
            style={{
              zIndex: 10,
              '--swiper-pagination-bullet-inactive-color': theme.palette.grey[500],
              '--swiper-pagination-bullet-inactive-opacity': '0.6',
              '--swiper-pagination-bullet-size': '15px',
              '--swiper-pagination-bullet-horizontal-gap': '6px',
              '--swiper-pagination-color': theme.palette.primary.main,
              padding: 5,
              height:"100%",
            }}
          >
            {allData.slice(0, 5).map((product) => (
              <SwiperSlide style={{ height: "100%",boxShadow:10}} key={product.id}>
                   <ProductCard flexDirection='row' button={true} productData={product}/>
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>
      </Container>
    </Box>
  );
}
