import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import AppRoutes from './routes';
import Footer from './components/core/Footer';
import Navbar from './components/navbar/Navbar';
import './App.css';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { toast, ToastContainer } from 'react-toastify';
import { useThemeContext } from './context/ThemeContext';
import { GoogleOAuthProvider } from '@react-oauth/google';
import ReactGA from 'react-ga';
import { generateToken, messaging } from './components/notifications/firebase';
import { onMessage } from 'firebase/messaging';
import ChatBotToggleButton from './components/chatbot/ChatBotToggleButton';


const TRACKING_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID;
ReactGA.initialize(TRACKING_ID);

const App = () => {
    const { theme } = useThemeContext();
   

    const ScrollToTop = () => {
        const { pathname } = useLocation();

        useEffect(() => {
            window.scrollTo(0, 0);

            // Track pageview on route change
            ReactGA.pageview(pathname);
        }, [pathname]);

        return null;
    };



    useEffect(()=>{
        generateToken();
        onMessage(messaging, (payload)=>{
            console.log(payload);
            toast.success(payload.notification.body);
        })
    },[])

    return (
        <GoogleOAuthProvider
            clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID}
        >
            <ScrollToTop />
            <MuiThemeProvider theme={theme}>
                <ToastContainer
                    stacked
                    progressStyle={{
                        backgroundColor: theme.palette.primary.main,
                    }}
                    theme={theme.palette.mode === 'dark' ? 'dark' : 'light'}
                    autoClose={800}
                />
                <CssBaseline />
                <Navbar />
                <AppRoutes />
                <Footer />
                <ChatBotToggleButton/>


            </MuiThemeProvider>
        </GoogleOAuthProvider>
    );
};

export default App;
