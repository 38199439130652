import { createChatBotMessage } from "react-chatbot-kit";
import Options from "./Options";
import { useTheme } from "@mui/material/styles";

const Config = () => {
  const theme = useTheme();


  const config = {
    botName: "VeginGo Bot",
    initialMessages: [createChatBotMessage("Hi, I'm here to help!")],

    widgets: [
      {
        widgetName: "options",
        widgetFunc: (props) => <Options {...props} />,
        mapStateToProps: ["messages"]
      },
    ],

    customStyles: {
      botMessageBox: {
        backgroundColor: theme.palette.primary.main, 
      },
      chatButton: {
        backgroundColor: theme.palette.primary.main,
      },
      userMessageBox: {
        backgroundColor: theme.palette.primary.main, 
      },
     
      
    },
  };

  return config;
};

export default Config;
