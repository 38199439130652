import React from 'react'
import Card from "@mui/material/Card"
import CardMedia from "@mui/material/CardMedia"
import Typography from "@mui/material/Typography"
import { useTheme } from '@emotion/react';
import Box from "@mui/material/Box"
import QuantitySelector from '../QuantitySelector';
import { useDispatch, useSelector } from 'react-redux';
import { removeItemFromLocalCart, removeItemFromServerCart } from '../../../redux/slices/cartSlice';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';







export default function CartListItem({ item }) {
    console.log(item)
    const theme = useTheme();
    const { isAuthenticated } = useSelector(state => state.auth);
    const dispatch = useDispatch();
    const handleRemoveItem = async () => {

        if (isAuthenticated) {
            dispatch(removeItemFromServerCart({productId: item.product.id}));
        }


        else {
            dispatch(removeItemFromLocalCart({productId: item.product.id}));
        }


    }
    return (
        <Card sx={{ px: 2, my: 1, justifyContent: 'space-between', display: "flex", width: "100%", boxShadow: "none" }}>
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>

                <CardMedia
                    component="img"
                    image={item.product.image}
                    alt={item.product.name}
                    sx={{ width: 60, height: 60, borderRadius: 2 }}
                />
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography variant="caption" sx={{ fontWeight: "800" }}>{item.product.name}</Typography>
                    <Typography sx={{ fontWeight: "600", color: "gray" }} variant="caption">x {item.quantity} ({item.product_unit})</Typography>

                </Box>


            </Box>

            <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <QuantitySelector item={item} />
                    <Box sx={{ display: "flex", gap: 2, mt: 0.5 }}>
                        <Typography variant='caption' sx={{ fontWeight: 500, color: item.product.discounted_price ? "gray" : theme.palette.primary.main, textDecoration: item.product.discounted_price ? 'line-through' : "none", fontWeight: 500 }}>₹ {item.product.price * item.quantity}</Typography>

                        <Typography color="primary" sx={{ fontWeight: 500 }} variant='caption'>₹ {item.product.discounted_price * item.quantity}</Typography>

                    </Box>


                </Box>

                <IconButton onClick={handleRemoveItem}>
                    <DeleteIcon fontSize='small' />

                </IconButton>




            </Box>













        </Card>
    )
}
