// BestSellers.js
import React, { useState } from 'react';
import Container from '@mui/material/Container';
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import SectionTitle from '../core/SectionTitle';
import CategoryFilter from './CategoryFilter';
import ProductCard from '../product/ProductCard';
import CustomSlider from './CustomSlider';

export default function BestSellers({ allProducts }) {
  const theme = useTheme();
  const [selectedCategoryId, setSelectedCategoryId] = useState(0);

  const handleCategoryChange = (category) => {
    setSelectedCategoryId(category);
  };

  const filteredProducts = selectedCategoryId === 0 ? allProducts : allProducts.filter(item => item.category_id === selectedCategoryId);

  return (
    <Container maxWidth="lg" sx={{ mt: 10 }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap" }}>
        <SectionTitle t1="Best Sellers" t2="Shop Our Most Popular Items" />
        <CategoryFilter selectedCategoryId={selectedCategoryId} onCategoryChange={handleCategoryChange} />
      </Box>

      <CustomSlider
        items={filteredProducts}
        renderSlide={(item) => <ProductCard productData={item}  />}
        maxSlides={10}
        navigationClass="best-seller"
      />
    </Container>
  );
}
