import React from "react";
import { Box, Grid, Typography, Button, CardMedia, Container } from "@mui/material";

const FreshFoodBanner = () => {
  return (
    <Box
      sx={{
        background: "url(https://res.cloudinary.com/dbi8wjrcl/image/upload/v1729676497/vegingo/f7f7f7_3_pio8fb.png)",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        mt: 10,
      }}
    >
      <Container maxWidth="lg">
        <Box>
          <Grid container rowGap={4} columnSpacing={4} alignItems="center">
            {/* Left Image Section */}
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "1rem",
                }}
              >
                {/* Image 1 */}
                <CardMedia
                  sx={{
                    borderRadius: "16px",
                    height: { xs: "200px", sm: "250px", md: "350px", lg: "400px" },
                    width: { xs: "100%", md: "48%" },
                    mb: { xs: 2, md: 5 },
                    overflow: "hidden",
                  }}
                >
                  <Box
                    component="img"
                    src="https://images.pexels.com/photos/9268304/pexels-photo-9268304.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                    alt="Farmer with vegetable"
                    sx={{
                      width: "100%",
                      height: "100%",
                      transition: "transform 0.3s ease-in-out",
                      "&:hover": {
                        transform: "scale(1.05)",
                      },
                    }}
                  />
                </CardMedia>

                {/* Image 2 */}
                <CardMedia
                  sx={{
                    borderRadius: "16px",
                    height: { xs: "200px", sm: "250px", md: "350px", lg: "400px" },
                    width: { xs: "100%", md: "48%" },
                    mt: { xs: 2, md: 5 },
                    overflow: "hidden",
                  }}
                >
                  <Box
                    component="img"
                    src="https://images.unsplash.com/photo-1607892408220-c80c0f8651d0?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    alt="Woman with vegetable basket"
                    sx={{
                      width: "100%",
                      height: "100%",
                      transition: "transform 0.3s ease-in-out",
                      "&:hover": {
                        transform: "scale(1.05)",
                      },
                    }}
                  />
                </CardMedia>
              </Box>
            </Grid>

            {/* Right Text Section */}
            <Grid item xs={12} md={6}>
              <Typography
                variant="body1"
                sx={{ color: "green", textAlign: { xs: "center", md: "left" }, fontSize: { xs: "0.9rem", sm: "1rem"} }}
              >
                FRESH FROM OUR FARM
              </Typography>
              <Typography
                variant="h4"
                component="h2"
                gutterBottom
                sx={{
                  fontFamily: "Playfair Display",
                  fontWeight: "bold",
                  color:"black",
                  textAlign: { xs: "center", md: "left" },
                  fontSize: { xs: "1.5rem", sm: "2rem", md: "2.5rem" }, // Adjusted for smaller screens
                }}
              >
                Trusted Organic Food for Health-Conscious Living
              </Typography>
              <Typography
                variant="body1"
                paragraph
                sx={{ textAlign: { xs: "center", md: "left" }, fontSize: { xs: "0.85rem", sm: "1rem" },color:"black", }}
              >
                Experience the freshness of organic produce delivered directly from our farms to your doorstep. We ensure
                that every product is grown without chemicals, so you can enjoy healthy and nutritious food.
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                paragraph
                sx={{color:"black", textAlign: { xs: "center", md: "left" }, fontSize: { xs: "0.8rem", sm: "0.9rem" } }}
              >
                Our commitment to sustainability and quality means you’re supporting local farmers and a healthier planet.
                Join our community of people who value real, wholesome food free from harmful pesticides and artificial
                ingredients.
              </Typography>
              {/* Subscribe Button */}
              <Box sx={{ display: "flex", justifyContent: { xs: "center", md: "flex-start" } }}>
                <Button variant="contained" color="success" sx={{ mt: 2 }}>
                  Subscribe ➔
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default FreshFoodBanner;
