import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import { Radio, RadioGroup, FormControlLabel, FormControl } from '@mui/material';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import paymentService from '../services/paymentService';
import CloseIcon from '@mui/icons-material/Close';
import UploadIcon from '@mui/icons-material/UploadFile';
import QRCodeDisplay from '../components/payment/QRCodeDisplay';
import { useDispatch, useSelector } from 'react-redux';
import Paper from "@mui/material/Paper";
import ReceiptIcon from '@mui/icons-material/Receipt';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import DiscountIcon from '@mui/icons-material/Discount';
import { createOrderFromCart } from '../redux/slices/orderSlice';

export default function Payment() {
    const [note, setNote] = useState('Please keep exact change handy to help us serve you better');
    const [receiptImage, setReceiptImage] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('');
    const navigate = useNavigate();
    const { billDetails, selectedAddress, orderNote,  selectedCoupon } = useSelector(state => state.order);

    console.log("selectedCoupon", selectedCoupon);

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            setReceiptImage(URL.createObjectURL(file));
            await uploadReceipt(file);
        }
    };

    const uploadReceipt = async (file) => {
        setUploading(true);
        try {
            const response = await paymentService.uploadReceipt(file);
            toast.success('Receipt uploaded successfully!', {
                position: 'top-center',
            });
            console.log('Receipt upload response:', response);
        } catch (error) {
            toast.error('Error uploading receipt. Please try again.');
            console.error('Upload error:', error);
        } finally {
            setUploading(false);
        }
    };

    const dispatch = useDispatch();

    const handlePlaceOrder = () => {
        toast.success('Order confirmed successfully');
        const addressId = selectedAddress.id;
        const deliveryCharge = billDetails.deliveryCharge;
        console.log(selectedCoupon)
        
        dispatch(createOrderFromCart({addressId, orderNote,selectedCoupon, deliveryCharge,selectedPaymentMethod}))
        navigate('/order-confirmed');
    };

    const handleRemoveImage = () => {
        setReceiptImage(null);
    };

    const handlePaymentMethodChange = (event) => {
        setSelectedPaymentMethod(event.target.value);
    };

    return (
        <Box sx={{ minHeight: '100vh' }}>
            <Container maxWidth="lg" sx={{ my: 5 }}>
                <Grid container columnSpacing={5} rowGap={5}>
                    <Grid item md={6} xs={12}>
                        <Typography variant="h5" sx={{ fontWeight: 500 }}>
                            Select Payment Method
                        </Typography>

                        <FormControl component="fieldset" sx={{ my: 2, width: '100%' }}>
                            <RadioGroup
                                value={selectedPaymentMethod}
                                onChange={handlePaymentMethodChange}
                            >

                                <Accordion expanded={selectedPaymentMethod === 'cod'}>
                                    <AccordionSummary
                                        expandIcon={<ArrowDropDownIcon />}
                                        aria-controls="cod-content"
                                        id="cod-header"
                                    >
                                        <FormControlLabel
                                            value="cod"
                                            control={<Radio />}
                                            label="Cash On Delivery"
                                        />
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography gutterBottom variant="body2">
                                            {note}
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion expanded={selectedPaymentMethod === 'wallet'}>
                                    <AccordionSummary
                                        expandIcon={<ArrowDropDownIcon />}
                                        aria-controls="wallet-content"
                                        id="wallet-header"
                                    >
                                        <FormControlLabel
                                            value="wallet"
                                            control={<Radio />}
                                            label="Wallets"
                                        />
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                            Mobile wallets are convenient for quick payments. Link your preferred wallet here to continue.
                                            <br />
                                            <strong>Note:</strong> This feature is currently not available.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion expanded={selectedPaymentMethod === 'card'}>
                                    <AccordionSummary
                                        expandIcon={<ArrowDropDownIcon />}
                                        aria-controls="card-content"
                                        id="card-header"
                                    >
                                        <FormControlLabel
                                            value="card"
                                            control={<Radio />}
                                            label="Add credit or debit cards"
                                        />
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                            Save your card details for seamless future transactions.
                                            <br />
                                            <strong>Note:</strong> This feature is currently not available.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion expanded={selectedPaymentMethod === 'netbanking'}>
                                    <AccordionSummary
                                        expandIcon={<ArrowDropDownIcon />}
                                        aria-controls="netbanking-content"
                                        id="netbanking-header"
                                    >
                                        <FormControlLabel
                                            value="netbanking"
                                            control={<Radio />}
                                            label="Netbanking"
                                        />
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                            Use net banking to pay directly from your bank account.
                                            <br />
                                            <strong>Note:</strong> This feature is currently not available.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion expanded={selectedPaymentMethod === 'upi'}>
                                    <AccordionSummary
                                        expandIcon={<ArrowDropDownIcon />}
                                        aria-controls="upi-content"
                                        id="upi-header"
                                    >
                                        <FormControlLabel
                                            value="upi"
                                            control={<Radio />}
                                            label="Add new UPI ID"
                                        />
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                            Link your UPI ID for faster payments directly through your bank.
                                            <br />
                                            <strong>Note:</strong> This feature is currently not available.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    
                    <Grid item md={6} xs={12}>
                        <Paper elevation={2} sx={{ p: 2, position: 'sticky', top: 20 }}>
                            <Box sx={{ py: 2 }}>
                                <Typography variant='body2' gutterBottom sx={{ fontWeight: 800, textTransform: "uppercase" }}>Bill details</Typography>
                                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                    <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                                        <ReceiptIcon sx={{ fontSize: "1rem" }} />
                                        <Typography variant='body2'>Sub Total</Typography>
                                    </Box>
                                    <Typography variant='body2'>₹ {billDetails.cartTotal}</Typography>
                                </Box>

                                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                    <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                                        <DeliveryDiningIcon sx={{ fontSize: "1rem" }} />
                                        <Typography variant='body2'>Delivery Charge</Typography>
                                    </Box>
                                    <Typography variant='body2'>{billDetails.deliveryCharge === 0 ? "FREE" : `- ₹ ${billDetails.deliveryCharge}`}</Typography>
                                </Box>

                                {billDetails.discount > 0 && (
                                    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                        <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                                            <DiscountIcon sx={{ fontSize: "1rem" }} />
                                            <Typography variant='body2'>Coupon Discount</Typography>
                                        </Box>
                                        <Typography variant='body2' color="primary">- ₹ {billDetails.discount}</Typography>
                                    </Box>
                                )}

                                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                    <Typography variant='subtitle2'>Grand Total</Typography>
                                    <Typography variant='subtitle2'>₹ {billDetails.grandTotal}</Typography>
                                </Box>
                            </Box>

                            <Button variant='contained' fullWidth sx={{ color: "white", mt: 2 }} onClick={handlePlaceOrder}>
                                Place Order
                            </Button>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}
