import React from 'react'
import Box from "@mui/material/Box"
import { useSelector } from 'react-redux';
import CartListItem from './CartListItem';


export default function CartList() {
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const localCart = useSelector((state) => state.cart.localCart);
    const serverCart = useSelector((state) => state.cart.serverCart);
    const cart = isAuthenticated ? serverCart : localCart;

    return (
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
            {
                cart.products.map((item, index) => (
                    <CartListItem key={index} item={item} />


                ))
            }

        </Box>
    )
}
