import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import wishlistService from '../../services/wishlistService';

// Initial State
const initialState = {
    serverWishlist: {
        products: [],
        count: 0,
    },
    localWishlist: {
        products: [],
        count: 0,
    },
    status: 'idle',
    loading: {},
    error: null,
};

// Fetch Server Wishlist
export const fetchServerWishlist = createAsyncThunk(
    'wishlist/fetchServerWishlist',
    async (_, { rejectWithValue }) => {
        try {
            const response = await wishlistService.getWishlistItems();
            console.log('Data', response.data);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || 'An error occurred');
        }
    }
);

// Fetch Local Wishlist
export const fetchLocalWishlist = createAsyncThunk(
    'wishlist/fetchLocalWishlist',
    async () => {
        const localWishlist =
            JSON.parse(localStorage.getItem('localWishlist')) || [];
        return localWishlist;
    }
);

// Add Item to Server Wishlist
export const addItemToServerWishlist = createAsyncThunk(
    'wishlist/addItemToServerWishlist',
    async (item, { rejectWithValue }) => {
        try {
            const response = await wishlistService.addItemToWishlist(item.id);
            return { product: item };
        } catch (error) {
            return rejectWithValue(error.response?.data || 'An error occurred');
        }
    }
);

// Add Item to Local Wishlist
export const addItemToLocalWishlist = createAsyncThunk(
    'wishlist/addItemToLocalWishlist',
    async (item) => {
        const localWishlist =
            JSON.parse(localStorage.getItem('localWishlist')) || [];
        const existingItemIndex = localWishlist.findIndex(
            (wishlistItem) => wishlistItem.product.id === item.id
        );

        if (existingItemIndex === -1) {
            localWishlist.push({ product: item });
        }

        localStorage.setItem('localWishlist', JSON.stringify(localWishlist));
        return item;
    }
);

// Remove Item from Server Wishlist
export const removeItemFromServerWishlist = createAsyncThunk(
    'wishlist/removeItemFromServerWishlist',
    async (itemId, { rejectWithValue }) => {
        try {
            await wishlistService.deleteWishlistItem(itemId);
            return itemId;
        } catch (error) {
            return rejectWithValue(error.response?.data || 'An error occurred');
        }
    }
);

// Remove Item from Local Wishlist
export const removeItemFromLocalWishlist = createAsyncThunk(
    'wishlist/removeItemFromLocalWishlist',
    async (itemId) => {
        const localWishlist =
            JSON.parse(localStorage.getItem('localWishlist')) || [];
        const updatedWishlist = localWishlist.filter(
            (item) => item.product.id !== itemId
        );
        localStorage.setItem('localWishlist', JSON.stringify(updatedWishlist));
        return itemId;
    }
);

// Wishlist Slice
const wishlistSlice = createSlice({
    name: 'wishlist',
    initialState,
    reducers: {
        clearServerWishlist: (state) => {
            state.serverWishlist = { products: [], count: 0 };
        },
        clearLocalWishlist: (state) => {
            state.localWishlist = { products: [], count: 0 };
        },
    },
    extraReducers: (builder) => {
        // Server Wishlist
        builder
            .addCase(fetchServerWishlist.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchServerWishlist.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.serverWishlist.products = action.payload;
                state.serverWishlist.count = action.payload.length;
            })
            .addCase(fetchServerWishlist.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            .addCase(addItemToServerWishlist.fulfilled, (state, action) => {
                const newProduct = action.payload;
                console.log(newProduct);
                // state.serverWishlist.products.push(newProduct); //Not working for some reason
                state.serverWishlist.count += 1;

                toast.success('Item added to wishlist', {
                    position: 'top-center',
                });
            })
            .addCase(
                removeItemFromServerWishlist.fulfilled,
                (state, action) => {
                    const itemId = action.payload;
                    state.serverWishlist.products =
                        state.serverWishlist.products.filter(
                            (item) => item.product.id !== itemId
                        );
                    state.serverWishlist.count -= 1;

                    toast.success('Item removed from wishlist', {
                        position: 'top-center',
                    });
                }
            );

        // Local Wishlist
        builder
            .addCase(fetchLocalWishlist.fulfilled, (state, action) => {
                state.localWishlist.products = action.payload;
                state.localWishlist.count = action.payload.length;
            })
            .addCase(addItemToLocalWishlist.fulfilled, (state, action) => {
                const newProduct = action.payload;
                const existingItem = state.localWishlist.products.find(
                    (item) => item.id === newProduct.id
                );

                if (!existingItem) {
                    state.localWishlist.products.push({
                        product: newProduct,
                    });
                    state.localWishlist.count += 1;

                    toast.success('Item added to wishlist', {
                        position: 'top-center',
                    });
                }
            })
            .addCase(removeItemFromLocalWishlist.fulfilled, (state, action) => {
                const itemId = action.payload;
                state.localWishlist.products =
                    state.localWishlist.products.filter(
                        (item) => item.product.id !== itemId
                    );
                state.localWishlist.count -= 1;

                toast.success('Item removed from wishlist', {
                    position: 'top-center',
                });
            });
    },
  },
  
);

export const { clearServerWishlist, clearLocalWishlist } =
    wishlistSlice.actions;
export default wishlistSlice.reducer;
