import React, { useEffect, useState } from 'react';
import { Typography, Box, Paper, Divider, useMediaQuery } from '@mui/material';
import { useTheme } from '@emotion/react';

const CountdownTimer = ({ days }) => {
    const calculateTimeLeft = (endDate) => {
        const difference = +new Date(endDate) - +new Date();
        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60)
            };
        }

        return timeLeft;
    };

    const [endDate, setEndDate] = useState(new Date(Date.now() + days * 24 * 60 * 60 * 1000));
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(endDate));
    useEffect(() => {
        const newEndDate = new Date(Date.now() + days * 24 * 60 * 60 * 1000);
        setEndDate(newEndDate);

        const timer = setInterval(() => {
            setTimeLeft(calculateTimeLeft(newEndDate));
        }, 1000);

        return () => clearInterval(timer);
    }, [days]);

    return (
        <Box sx={{display:"flex",justifyContent:"center",alignItems:'center'}}>
            {timeLeft.days != null && (
                <Box sx={{ display: "flex", gap: 1, my: 1, mx:"auto" }}>
                    <Paper elevation={3} sx={{ display: "flex",textAlign:"center", width: "50px", height: "50px", p: 0.5, backgroundColor: "red", flexDirection: "column" }}>
                        <Typography sx={{ color: "white", fontWeight: "bold" }} variant='body2'>{timeLeft.days}</Typography>
                        <Divider orientation='horizontal' sx={{ my: 0.2, borderColor:"white" }} flexItem />
                        <Typography sx={{ color: "white" }} variant='caption'>Days</Typography>

                    </Paper>
                    <Paper elevation={3} sx={{ display: "flex",textAlign:"center", width: "50px", height: "50px", p: 0.5, backgroundColor: "red", flexDirection: "column" }}>
                        <Typography sx={{ color: "white", fontWeight: "bold" }} variant='body2'>{timeLeft.hours}</Typography>
                        <Divider orientation='horizontal' sx={{ my: 0.2, borderColor:"white" }} flexItem />
                        <Typography sx={{ color: "white" }} variant='caption'>Hours</Typography>

                    </Paper>
                    <Paper elevation={3} sx={{ display: "flex",textAlign:"center", width: "50px", height: "50px", p: 0.5, backgroundColor: "red", flexDirection: "column" }}>
                        <Typography sx={{ color: "white", fontWeight: "bold" }} variant='body2'>{timeLeft.minutes}</Typography>
                        <Divider orientation='horizontal' sx={{ my: 0.2, borderColor:"white" }} flexItem />
                        <Typography sx={{ color: "white" }} variant='caption'>Minutes</Typography>

                    </Paper>
                    <Paper elevation={3} sx={{ display: "flex",textAlign:"center", width: "50px", height: "50px", p: 0.5, backgroundColor: "red", flexDirection: "column" }}>
                        <Typography sx={{ color: "white", fontWeight: "bold" }} variant='body2'>{timeLeft.seconds}</Typography>
                        <Divider orientation='horizontal' sx={{ my: 0.2, borderColor:"white" }} flexItem />
                        <Typography sx={{ color: "white" }} variant='caption'>Seconds</Typography>

                    </Paper>

                </Box>
            )}
        </Box>
    );
};

export default CountdownTimer;
