import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { I18nextProvider } from "react-i18next";
import App from "./App";
import i18n from "./i18n/i18n";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from "./redux/store";
import "react-toastify/dist/ReactToastify.css";
import { ThemeProvider } from "./context/ThemeContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
  <ThemeProvider>
  <Provider store={store}>
      <I18nextProvider i18n={i18n}>
      <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
      </I18nextProvider>
    </Provider>

  </ThemeProvider>
   
  </BrowserRouter>
);
