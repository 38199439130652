import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';
import { Autoplay, Pagination, EffectFade } from 'swiper/modules';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Container, Grid } from '@mui/material';
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import { Link } from 'react-router-dom';



const HeroCarousel = () => {

  const baseCloudinaryURL = process.env.REACT_APP_CLOUDINARY_BASE_URL;
  const optimizedImages = {
    heroImg1: `${baseCloudinaryURL}/w_800,h_500,c_scale,q_auto:good,f_auto/v1722407335/vegingo/Hero1_hdyuwf.jpg`,
    heroImg2: `${baseCloudinaryURL}/w_800,h_500,c_scale,q_auto:good,f_auto/v1722407333/vegingo/Hero2_vmjq5r.jpg`,
    heroImg3: `${baseCloudinaryURL}/w_800,h_500,c_scale,q_auto:good,f_auto/v1722407333/vegingo/Hero3_loghaj.jpg`,
    heroImg4: `${baseCloudinaryURL}/w_800,h_500,c_scale,q_auto:good,f_auto/v1722407334/vegingo/Hero4_yxdhhg.jpg`
  };
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const slides = [
    { id: 1, image: optimizedImages.heroImg1, title: 'Organic Vegetables', subtitle: '100% Healthy & Affordable' },
    { id: 2, image: optimizedImages.heroImg2, title: "Nature's Best", subtitle: "Freshly Harvested Delights" },
    { id: 3, image: optimizedImages.heroImg3, title: 'Locally Grown Goodness', subtitle: 'Farm to Fork Freshness' },
    { id: 4, image: optimizedImages.heroImg4, title: 'Nourish with Nature', subtitle: 'Color Your Plate' }
  ];


  return (
    <Container maxWidth="lg" sx={{ my: 2 }} >

      <Grid sx={{ height: "100%" }} columnSpacing={2} rowGap={2} container>
        <Grid item md={6} xs={12}>
          <Swiper
            pagination={{ clickable: true, enabled: isSmallScreen ? false : true }}
            effect='fade'
            loop={true}
            fadeEffect={{ crossFade: true }}
            speed={2000}
            autoplay={{ delay: 3000 }}
            modules={[EffectFade, Autoplay, Pagination]}
            className="mySwiper1"
            style={{
              '--swiper-pagination-bullet-inactive-color': theme.palette.grey[200],
              '--swiper-pagination-bullet-inactive-opacity': '0.6',
              '--swiper-pagination-bullet-size': '12px',
              '--swiper-pagination-bullet-horizontal-gap': '6px',
              '--swiper-pagination-color': theme.palette.primary.main,
              width: "100%",
              height: "100%",
              minHeight: "40vh",
            }}
          >
            {slides.map((slide) => (
              <SwiperSlide key={slide.id}>
                <Box
                  sx={{
                    textAlign: 'center',
                    width: '100%',
                    height: "100%",
                    backgroundImage: `url(${slide.image})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius:2
                  }}
                >
                  <Box sx={{ maxWidth: '60%', margin: '0 auto', color: "white", backgroundColor: "rgba(0,0,0,0.3)", p: 2, borderRadius: 2, backdropFilter: "blur(4px)", display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <Typography color={"primary"} variant={isSmallScreen ? "h4" : "h3"} sx={{ fontWeight: 500 , fontFamily:"Playfair Display"}}>
                      {slide.title}
                    </Typography>
                    <Typography variant={isSmallScreen ? "h7" : "h6"} gutterBottom>
                      {slide.subtitle}
                    </Typography>

                    <Link to="/shop">
                    <Button size="small" variant="contained" sx={{ backgroundColor: theme.palette.primary.main,  color: "white", mt: 1 }}>
                      Shop Now
                    </Button>
                    </Link>
                  
                  </Box>
                </Box>
              </SwiperSlide>
            ))}
          </Swiper></Grid>

        <Grid item md={6} xs={12}>
          <Grid container columnSpacing={2} >

            <Grid item md={6} xs={6}>
              <Grid container rowGap={2}>
                <Grid item md={12} xs={12}>
                  <img style={{ width: "100%", height: "100%", objectFit: "cover", borderRadius:10 }} src="mid-banner1.jpg" />
                </Grid>
                <Grid item md={12} xs={12}>
                  <img style={{ width: "100%", height: "100%", objectFit: "cover",borderRadius:10 }} src="/mid-banner2.jpg" />

                </Grid>

              </Grid>

            </Grid>

            <Grid item md={6} xs={6}>
              <img src="/rhs-banner.jpg" style={{ width: "100%", height: "100%", objectFit: "cover",borderRadius:10 }} alt="" />
            </Grid>




          </Grid>
        </Grid>
      </Grid>
    </Container>



  );
};

export default HeroCarousel;
