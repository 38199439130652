import axios from "axios";
import { api } from "../apiCollection";

const submitContactForm = async (name, email, subject, message) => {
  try {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("subject", subject);
    formData.append("message", message);

    const response = await axios.post(api.contact, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return response.data;
  } catch (error) {
    if (error.response) {
      console.error(
        "Error submitting contact form: Server responded with an error",
        error.response.data
      );
      throw new Error(
        `Contact form submission failed: ${
          error.response.data.message || error.response.statusText
        }`
      );
    } else if (error.request) {
      console.error(
        "Error submitting contact form: No response received",
        error.request
      );
      throw new Error(
        "Contact form submission failed: No response received from the server. Please try again later."
      );
    } else {
      console.error(
        "Error submitting contact form: Unexpected error",
        error.message
      );
      throw new Error(`Contact form submission failed: ${error.message}`);
    }
  }
};

export default {
  submitContactForm,
};
