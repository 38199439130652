import React from 'react';
import { Box, Typography, List, ListItem, Divider, Button, IconButton } from '@mui/material';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import CloseIcon from "@mui/icons-material/Close";
import DownloadIcon from '@mui/icons-material/Download';  
import { useDispatch } from 'react-redux';
import { downloadInvoice } from '../../redux/slices/orderSlice';

const orderItems = [
    {
        name: 'White Rolling Paper (Small) - Stash Pro',
        quantity: '1 pack (40 pieces)',
        price: '₹45'
    },
    {
        name: 'Brown Rolling Paper (King Size) - Stash Pro',
        quantity: '1 unit (32 pieces)',
        price: '₹50'
    },
    {
        name: 'Ultimate Rolling Paper with Filter Tips & Crushing Tray (King Size, Unbleached) - Mozo',
        quantity: '1 pack (32 pieces)',
        price: '₹90'
    },
    {
        name: 'Brown Rolling Paper Cones - Stash Pro',
        quantity: '6 pieces',
        price: '₹90'
    }
];

const OrderDetails = ({ onClose, order, dateTime }) => {
    const dispatch = useDispatch();
    
    const handleDownloadInvoice = () => {
        dispatch(downloadInvoice(order.id))
        
        
        console.log('Invoice download initiated');
    };

    return (
        <Box sx={{ width: "100%" }}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <IconButton onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            </Box>
            <Typography variant="h5" gutterBottom>
                {order.order_item.length} items in this order
            </Typography>

            <List>
                {order.order_item.map((item, index) => (
                    <Box key={index}>
                        <ListItem sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Box>
                                <Typography variant="subtitle1">{item.product.name}</Typography>
                                <Typography variant="body2" color="textSecondary">{item.quantity} {item.product_unit}</Typography>
                            </Box>
                            <Typography variant="subtitle1">₹ {item.product.price*item.quantity}</Typography>
                        </ListItem>
                        {index < order.order_item.length - 1 && <Divider />}
                    </Box>
                ))}
            </List>

            <Box mt={3}>
                <Typography variant="h6" gutterBottom>
                    Order details
                </Typography>
                <Typography variant="body2" color="textSecondary">
                    Order ID: ORD{order.id}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                    Payment: Pay on Delivery
                </Typography>
<Typography variant="body2" color="textSecondary">
                    Deliver to: {order.address.user_name}, {order.address.building_name_area}, {order.address.landmark}, {order.address.city}, {order.address.pincode}, {order.address.country}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                    Order placed on: {dateTime}
                </Typography>
            </Box>

            <Box mt={3} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap:"wrap" }}>
                <Typography variant="body2">Need help with your order?</Typography>
                <Button variant="contained" sx={{color:"white"}} color="primary" startIcon={<ShoppingCartIcon />}>
                    Chat with us
                </Button>
            </Box>

            
            <Box mt={2} sx={{ display: 'flex', justifyContent: 'center' }}>
                <Button 
                    variant="contained" 
                    sx={{ color: "white" }} 
                    color="secondary" 
                    startIcon={<DownloadIcon />} 
                    onClick={handleDownloadInvoice}
                >
                    Download Invoice
                </Button>
            </Box>
        </Box>
    );
};

export default OrderDetails;
