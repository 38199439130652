import { Person } from '@mui/icons-material';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton'
import Box from '@mui/material/Box'
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemText from "@mui/material/ListItemText"
import { useTheme } from '@mui/material/styles';
import Drawer from "@mui/material/Drawer"
import ListItemButton from "@mui/material/ListItemButton"
import Button from "@mui/material/Button"
import React from 'react'
import { Link, NavLink, useNavigate } from 'react-router-dom';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import PinterestIcon from '@mui/icons-material/Pinterest';
import InstagramIcon from '@mui/icons-material/Instagram';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../redux/slices/authSlice';
import { persistor } from '../../redux/store';

export default function SideDrawer({ onDrawerClose, menuItems, navDrawerOpen }) {
    const theme = useTheme();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleLogout = async () => {
        await dispatch(logout());
        persistor.purge();
        navigate("/account/login");
    };


    const {isAuthenticated} = useSelector(state=>state.auth);
    return (
        <Drawer anchor="left" open={navDrawerOpen} onClose={onDrawerClose}>
            <Box
                sx={{ width: 250, height: "100vh", bgcolor: theme.palette.background.default, display: "flex", flexDirection: "column" }}
                role="presentation"
                onClick={onDrawerClose}
                onKeyDown={onDrawerClose}
            >
                {/* use logo instead */}
                <img src="/Vegingo-logo-text.png" width={150} style={{marginTop:"0.5rem", marginLeft:"0.5rem"}}/>

                <List sx={{ height: "100%" }} disablePadding>
                    {menuItems.map(({ text, path }) => (
                        <NavLink key={text} to={path} end style={({ isActive }) => ({
                            color: isActive ? theme.palette.primary.main : theme.palette.text.primary
                        })}>
                            <ListItemButton>
                                <ListItem disablePadding>

                                    <ListItemText primaryTypographyProps={{ style: { fontWeight: 500 } }} color={theme.palette.text.primary} primary={text} />
                                </ListItem>

                            </ListItemButton>


                        </NavLink>

                    ))}
                </List>

                

                    <Box onClick={handleLogout} sx={{ bgcolor: theme.palette.primary.main,  py: 1 }}>

                        {
                            isAuthenticated ?  (
                            <Button fullWidth sx={{color:"white"}}>
                                <LogoutOutlinedIcon sx={{ fontSize: "1.3rem", mr: 1 }}/>
                                <Typography variant='body1' textTransform="uppercase">Logout</Typography>

                                

                            </Button>
                        ):(<Button fullWidth sx={{ color: "white" }}>
                            
                            <Person sx={{ fontSize: "1.3rem", mr: 1 }} />
                            <Typography variant='body1' textTransform="uppercase">Login</Typography>
                        </Button>)
                        }

                       

                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, justifyContent: "center", mt: 1, color: "white" }}>
                            <IconButton color="inherit">
                                <TwitterIcon sx={{ fontSize: '1.2rem' }} />
                            </IconButton>
                            <IconButton color="inherit">
                                <FacebookIcon sx={{ fontSize: '1.2rem' }} />
                            </IconButton>
                            <IconButton color="inherit">
                                <PinterestIcon sx={{ fontSize: '1.2rem' }} />
                            </IconButton>
                            <IconButton color="inherit">
                                <InstagramIcon sx={{ fontSize: '1.2rem' }} />
                            </IconButton>
                        </Box>

                    </Box>




                

            </Box>
        </Drawer>
    )
}
