import React, { useEffect, useState } from 'react';
import ShoppingCart from '@mui/icons-material/ShoppingCart';
import Person from "@mui/icons-material/Person"
import Favorite from "@mui/icons-material/Favorite"
import MenuIcon from "@mui/icons-material/Menu"
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import { useDispatch, useSelector } from 'react-redux';
import CartDrawer from '../cart/cart-drawer/CartDrawer';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container';
import Badge from "@mui/material/Badge"
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Link, NavLink } from 'react-router-dom';
import SideDrawer from './SideDrawer';
import { useTranslation } from 'react-i18next';

import SearchBar from "./SearchBar"

import ThemeToggleButton from '../core/ThemeToggleButton';


const menuItems = [
    { text: 'Home', path: '/' },
    { text: 'Shop', path: '/shop' },
    { text: "About Us", path: '/about-us' },
    { text: "Blog", path: '/blog' },
    { text: 'Contact Us', path: '/contact-us' },
];

const foodCategories = {
    Fruits: ["Apple", "Orange", "Plum", "Jamun Fruit", "Cherry", "Elderberry", "Watermelon"],
    Vegetables: ["Beetroot", "Broccoli", "Capsicum", "Cucumber", "Potato", "Coriander", "Parsley"],
};



export default function MenuBar() {
    const theme = useTheme();

    const { t } = useTranslation('navbar');

    const [categoryDropdown, setCategoryDropdown] = useState(false);
    const [navDrawerOpen, setNavDrawerOpen] = useState(false);
    const [cartDrawerOpen, setCartDrawerOpen] = useState(false);


    const { isAuthenticated } = useSelector((state) => state.auth);
    const isMediumScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));

    const localCart = useSelector(state => state.cart.localCart);
    const serverCart = useSelector(state => state.cart.serverCart);
    const cart = isAuthenticated ? serverCart : localCart;


    const localWishlist = useSelector(state => state.wishlist.localWishlist);
    const serverWishlist = useSelector(state => state.wishlist.serverWishlist);
    const wishlist = isAuthenticated ? serverWishlist : localWishlist;



    const handleMouseEnter = (submenu, text) => {
        if (submenu && text === "Best Sellers") {
            setCategoryDropdown(true)
        }

    }

    const handleMouseLeave = (submenu, text) => {
        if (submenu && text === "Best Sellers") {
            setCategoryDropdown(false)
        }

    }

    const toggleNavDrawer = () => {
        setNavDrawerOpen(prev => !prev);
    }

    const toggleCartDrawer = () => {
        setCartDrawerOpen(prev => !prev);
    }



    return (
        <Box width="100%" sx={{ bgcolor: theme.palette.background.default }}>
            <Container sx={{ py: 1 }} maxWidth="xl">
                <Box position="static" sx={{ boxShadow: 'none', backgroundColor: theme.palette.background.default, color: theme.palette.text.primary }}>
                    <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}  >

                        <Box sx={{ flex: 3, display: "flex", gap: 4 }}>
                            <Link to="/">
                                <img src='/Vegingo-logo-text.png' width={120} ></img>
                            </Link>

                            <SearchBar />


                        </Box>



                        {/* menu items on loarge and search bar on small screen */}

                        {
                            !isMediumScreen && (<Box sx={{ flex: 6, display: 'flex', gap: '1.5rem', justifyContent: "center" }}>
                                {menuItems.map(({ text, path, submenu }) => (
                                    <Box
                                        sx={{ position: "relative" }}
                                        key={text}
                                        onMouseEnter={() => handleMouseEnter(submenu, text)}
                                        onMouseLeave={() => handleMouseLeave(submenu, text)}
                                    >
                                        <NavLink
                                            to={path}
                                            end
                                            style={({ isActive }) => ({
                                                color: isActive ? theme.palette.primary.main : theme.palette.text.primary
                                            })}
                                        >
                                            <Typography
                                                variant="body2"
                                                sx={{ fontWeight: "600", display: 'flex', alignItems: 'center', "&:hover": { color: theme.palette.primary.main } }}
                                                component="span"
                                            >
                                                {t(text)}
                                                {submenu && <ArrowDropDown sx={{ ml: 0.5, color: "gray" }} />}
                                            </Typography>
                                        </NavLink>

                                    </Box>
                                ))}
                            </Box>)
                        }







                        {/* menu button */}
                        {isMediumScreen ? (
                            <IconButton
                                edge="end"
                                color="inherit"
                                aria-label="open drawer"
                                onClick={toggleNavDrawer}
                            >
                                <MenuIcon />
                            </IconButton>
                        ) : (
                            /* icon buttons */
                            <Box sx={{ flex: 3, display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: 1 }}>

                                <NavLink to={isAuthenticated ? "/account" : "account/login"} end style={({ isActive }) => ({
                                    color: isActive ? theme.palette.primary.main : theme.palette.text.primary
                                })}>
                                    <IconButton color="inherit">
                                        <Person sx={{ fontSize: "1.3rem" }} />
                                    </IconButton>
                                </NavLink>




                                <IconButton onClick={toggleCartDrawer} color="inherit">
                                    <Badge sx={{
                                        "& .MuiBadge-badge": {
                                            color: "white"
                                        }
                                    }} badgeContent={cart.count} color="primary" >
                                        <ShoppingCart sx={{ fontSize: "1.3rem" }} />
                                    </Badge>
                                </IconButton>
                                {/* <NavLink to="/favorite" end style={({ isActive }) => ({
                                    color: isActive ? theme.palette.primary.main : theme.palette.text.primary
                                })}>
                                    <IconButton color="inherit">
                                        <Badge badgeContent={wishlist.count} color="primary" sx={{
                                            "& .MuiBadge-badge": {
                                                color: "white"
                                            }
                                        }}>
                                            <Favorite sx={{ fontSize: "1.3rem" }} />
                                        </Badge>
                                    </IconButton>
                                </NavLink> */}


                                {!isMediumScreen && <ThemeToggleButton />}



                            </Box>
                        )}

                    </Box>
                </Box>
            </Container>


            <SideDrawer onDrawerClose={toggleNavDrawer} menuItems={menuItems} navDrawerOpen={navDrawerOpen} />

            <CartDrawer toggleDrawer={toggleCartDrawer} drawerOpen={cartDrawerOpen} />
        </Box>
    );
}
