// CartSummary.js
import React, { useState, useEffect } from 'react';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import LinearProgress from "@mui/material/LinearProgress";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import { useSelector, useDispatch } from 'react-redux';
import { useTheme } from '@emotion/react';
import { useNavigate } from 'react-router-dom';
import { setBillDetails, setOrderNote, setSelectedCoupon } from '../../redux/slices/orderSlice';
import CouponModal from './CouponModal';
import ReceiptIcon from '@mui/icons-material/Receipt';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import DiscountIcon from '@mui/icons-material/Discount';

export default function CartSummary() {
  const localCart = useSelector(state => state.cart.localCart);
  const serverCart = useSelector(state => state.cart.serverCart);
  const { isAuthenticated } = useSelector(state => state.auth);
  const cart = isAuthenticated ? serverCart : localCart;
  const { orderNote } = useSelector(state => state.order);
  

  const dispatch = useDispatch();
  const theme = useTheme();
  const [note, setNote] = useState(orderNote || "");
  const [openModal, setOpenModal] = useState(false);
  const [coupon, setCoupon] = useState(null);

  const freeShippingThreshold = 200;
  const progressPercentage = Math.min((cart.total / freeShippingThreshold) * 100, 100);
  const amountRemaining = Math.max(freeShippingThreshold - cart.total, 0);
  const deliveryCharge = amountRemaining === 0 ? 0 : 30;

  const calculateDiscount = () => {
    return coupon ? coupon.discount_type === "percentage" ? cart.total / 100 * coupon.discount_amount : coupon.discount_amount : 0;
  };

  const calculateGrandTotal = () => {
    return cart.total + deliveryCharge - calculateDiscount();
  };

  const navigate = useNavigate();

  const handlePlaceOrderLocal = () => {
    dispatch(setOrderNote(note));

    const billDetails = {
      cartTotal: cart.total,
      deliveryCharge,
      discount: calculateDiscount(),
      grandTotal: calculateGrandTotal(),
    };
    dispatch(setBillDetails(billDetails));
    
    if(coupon) {
      const couponCode = coupon.coupon_code;
      dispatch(setSelectedCoupon(couponCode));
    }


    navigate("/checkout/address");
  };


  return (
    <Box>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Typography gutterBottom variant="body2" fontWeight={800}>Coupon</Typography>
        <Box sx={{ mb: 2, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          {coupon ? (
            <Box>
              <Typography variant="body2">Coupon Applied</Typography>
              <Typography color="primary" fontWeight={500} variant='caption'>
                You saved additional ₹{calculateDiscount()}
              </Typography>
            </Box>
          ) : (
            <Typography variant='body2'>Add coupon</Typography>
          )}
          <Button variant="outlined" color="primary" onClick={() => setOpenModal(true)}>
            {coupon ? "Change" : "Add"}
          </Button>
        </Box>
        <Divider flexItem />

        <Typography variant='caption' gutterBottom sx={{ fontWeight: 700, textTransform: "uppercase", mt: 2 }}>
          Free shipping for any order above <span style={{ color: theme.palette.primary.main }}>₹ {freeShippingThreshold.toFixed(2)}</span>
        </Typography>
        <Typography gutterBottom variant='caption' sx={{ fontWeight: 700, color: "gray", textTransform: "uppercase" }}>
          Spend <span style={{ color: theme.palette.primary.main }}>₹ {amountRemaining.toFixed(2)}</span> for free shipping
        </Typography>

        <Box sx={{ position: 'relative', width: '100%', my: 3 }}>
          <LinearProgress variant="determinate" value={progressPercentage} sx={{ height: 12, borderRadius: 5 }} />
          <Box sx={{
            position: 'absolute', top: 0, left: '50%', transform: 'translateX(-50%)',
            height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center',
            color: 'white', fontWeight: 'bold', fontSize: "0.6rem"
          }}>
            {progressPercentage >= 10 && <span>{progressPercentage.toFixed(0)}%</span>}
          </Box>
        </Box>
      </Box>

      <Divider flexItem sx={{ my: 2 }} />

      <Box sx={{ py: 2 }}>
        <Typography variant='body2' gutterBottom sx={{ fontWeight: 800, textTransform: "upper" }}>Bill details</Typography>

        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
            <ReceiptIcon sx={{ fontSize: "1rem" }} />
            <Typography variant='body2'>Sub Total</Typography>
          </Box>
          <Typography variant='body2'>₹ {cart.total}</Typography>
        </Box>

        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
            <DeliveryDiningIcon sx={{ fontSize: "1rem" }} />
            <Typography variant='body2'>Delivery Charge</Typography>
          </Box>
          <Typography variant='body2'>{deliveryCharge === 0 ? "FREE" : `₹ ${deliveryCharge}`}</Typography>
        </Box>

        {coupon && (
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
              <DiscountIcon sx={{ fontSize: "1rem" }} />
              <Typography variant='body2'>Coupon Discount</Typography>
            </Box>
            <Typography variant='body2' color="primary">- ₹ {calculateDiscount()}</Typography>
          </Box>
        )}

        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant='subtitle2'>Grand Total</Typography>
          <Typography variant='subtitle2'>₹ {calculateGrandTotal()}</Typography>
        </Box>
      </Box>


      <Box sx={{ mt: 2 }}>
        <Typography variant="subtitle2" gutterBottom sx={{ fontWeight: 'bold' }}>
          Add a note to your order:
        </Typography>
        <TextField
          variant="outlined"
          fullWidth
          multiline
          rows={3}
          value={note}
          onChange={(e) => setNote(e.target.value)}
          placeholder="Add your note here..."
        />
      </Box>

      <CouponModal
        open={openModal}
        handleClose={() => setOpenModal(false)}
        onSelectCoupon={(coupon) => setCoupon(coupon)}
      />

      <Box sx={{ mt: 2 }}>
        <Button
          onClick={handlePlaceOrderLocal}
          variant="contained"
          color="primary"
          fullWidth
          sx={{ color: "white" }}
        >
          Place Order
        </Button>
      </Box>
    </Box>
  );
}
