import React from 'react';
import { useTranslation } from 'react-i18next';
import { Select, MenuItem } from '@mui/material';

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();

  const handleChange = (event) => {
    i18n.changeLanguage(event.target.value);
  };

  return (
       <Select
        value={i18n.language}
        onChange={handleChange}
        displayEmpty
        size="small"
        variant='outlined'
        sx={{ fontSize: '0.75rem', height: '25px', my:1, color:"white", border:"0.5px solid white", '& .MuiSelect-icon': {
          color: 'white', 
        }, '.MuiOutlinedInput-notchedOutline': { border: 0 }  }} 
      >
        <MenuItem value="en">English</MenuItem>
        <MenuItem value="hi">हिन्दी</MenuItem>
      </Select>

     
  );
};

export default LanguageSwitcher;
