import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import authService from "../../services/authService";
import localStorageHelper from "../../helpers/localStorageHelper";
import { addItemToServerCart, fetchServerCart, clearLocalCart } from  "./cartSlice"


const userData = localStorageHelper.getItem("user");

const initialState = {
  isAuthenticated: !!userData,
  user: userData,
  token: userData ? userData.token : null,
  error: null,
};

export const login = createAsyncThunk(
  "auth/login",
  async ({ email, password }, { dispatch, rejectWithValue }) => {
    try {
      const data = await authService.login(email, password);
      return data;
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || error.message || "Login failed";
      console.error("Error during login:", error);
      return rejectWithValue(errorMessage);
    }
  }
);

export const signup = createAsyncThunk(
  "auth/signup",
  async (
    { name, email, mobileNumber, password, confirmPassword },
    { rejectWithValue }
  ) => {
    try {
      const data = await authService.signup(
        name,
        email,
        mobileNumber,
        password,
        confirmPassword
      );

      return data;
    } catch (error) {
      console.log(error);
      const errorMessage =
        error.response?.data?.message || error.message || "Signup failed";
      console.error("Error during signup:", error);
      return rejectWithValue(errorMessage);
    }
  }
);

export const googleLogin = createAsyncThunk(
  "auth/googleLogin",
  async (code, { rejectWithValue }) => {
    try {
      const response = await authService.googleLogin(code);
      return response.data;
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || error.message || "Google login failed";
      console.error("Error during Google login:", error);
      return rejectWithValue(errorMessage);
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      state.isAuthenticated = false;
      state.user = null;
      state.token = null;
      state.error = null;
      authService.logout();
      localStorageHelper.removeItem("user");
      toast.success("Logged out successfully", {
        position: "top-center",
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.fulfilled, (state, action) => {
        state.isAuthenticated = true;
        state.user = action.payload.data;
        state.token = action.payload.data.token;
        state.error = null;
        localStorageHelper.setItem("user", action.payload.data);
        toast.success("Login successful!", {
          position: "top-center",
        });

        // if (state.cart.products && state.cart.products.length > 0) {
        //   action.asyncDispatch((state.cart.products));
        // }
      })
      .addCase(login.rejected, (state, action) => {
        state.isAuthenticated = false;
        state.user = null;
        state.token = null;
        state.error = action.payload;
        toast.error(`${action.payload}`, {
          position: "top-center",
        });
      })
      .addCase(signup.fulfilled, (state, action) => {
        state.isAuthenticated = true;
        state.user = action.payload.data;
        state.token = action.payload.data.token;
        state.error = null;
        localStorageHelper.setItem("user", action.payload.data);
        toast.success("Signup successful!", {
          position: "top-center",
        });
      })
      .addCase(signup.rejected, (state, action) => {
        state.isAuthenticated = false;
        state.user = null;
        state.token = null;
        state.error = action.payload;
        toast.error(`${action.payload}`, {
          position: "top-center",
        });
      })
      .addCase(googleLogin.fulfilled, (state, action) => {
        state.isAuthenticated = true;
        state.user = action.payload;
        state.token = action.payload.token;
        state.error = null;
        localStorageHelper.setItem("user", action.payload);
        toast.success("Google login successful!", {
          position: "top-center",
        });

        // if (state.products && state.products.length > 0) {
        //   action.asyncDispatch(mergeCart(state.cart.products));
        // }
      })
      .addCase(googleLogin.rejected, (state, action) => {
        state.isAuthenticated = false;
        state.user = null;
        state.token = null;
        state.error = action.payload;
        toast.error(`Google login failed: ${action.payload}`, {
          position: "top-center",
        });
      });
  },
});

export const { logout } = authSlice.actions;

export default authSlice.reducer;
