import React from 'react'
import ListItemText from "@mui/material/ListItemText"
import ListItemAvatar from "@mui/material/ListItemAvatar"
import Avatar from "@mui/material/Avatar"
import Box from "@mui/material/Box"
import Typography from '@mui/material/Typography';
import Button from "@mui/material/Button"
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import OrderDetails from './OrderDetails'

export default function OrderItem({ order, onViewDetails, onClose, showDetails }) {

    const dateString = order.address.created_at;
    const date = new Date(dateString);

    const options = { day: 'numeric', month: 'long', year: 'numeric' };
    const timeOptions = { hour: '2-digit', minute: '2-digit', hour12: true, hourCycle: 'h12' };
    const formattedDate = date.toLocaleDateString('en-GB', options);
    const formattedTime = date.toLocaleTimeString('en-GB', timeOptions);
    const formattedDateTime = `${formattedDate}, ${formattedTime}`;

    console.log(formattedDate);

    return (
        <Box width={"100%"} display={"flex"} flexDirection={"column"} justifyContent={"space-between"} alignItems={"flex-start"} >
            {showDetails ? (
                // Order details view
               <OrderDetails order={order} onClose={onClose} dateTime={formattedDateTime}/>
            ) : (
                // Order summary view
                <Box width={"100%"} display={"flex"} flexDirection={{ xs: "column", sm: "row" }} justifyContent={"space-between"} alignItems={{ xs: "flex-start", md: "center" }} gap={1}>
                    <Box sx={{display:"flex", alignItems:"center"}}>
                    <ListItemAvatar>
                        <Avatar>
                            <ShoppingBagIcon />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={
                            <Typography variant="body1">
                                ORD {order.id} &middot; <strong> ₹ {order.grand_total}</strong>
                            </Typography>
                        }
                        secondary={
                            <Typography variant="body2" color="textSecondary">
                                Placed on {formattedDateTime}
                            </Typography>
                        }
                    />
                        </Box>
                   
                    <Button size='small' variant="outlined" onClick={onViewDetails} sx={{ borderRadius: "1.2rem" }}>
                        View Details
                    </Button>
                </Box>
            )}
        </Box>
    );
}
