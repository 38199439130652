import * as React from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import RatingReview from './RatingReview';

const TabPanel = ({ value, index, children }) => (
    <div hidden={value !== index}>
        {value === index && (
            <Box
                sx={{ p: 3, border: '1px solid lightgray', minHeight: '600px' }}
            >
                <Typography>{children}</Typography>
            </Box>
        )}
    </div>
);

export default function CenteredTabs({ productData }) {
    const [value, setValue] = React.useState(0);
    const theme = useTheme();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const productId = productData.id;
    // console.log(productId);

    return (
        <Box sx={{ width: '100%', bgcolor: 'background.paper', my: 10 }}>
            <Tabs
                TabIndicatorProps={{
                    sx: {
                        bgcolor: theme.palette.primary.main,
                        color: 'red',
                    },
                }}
                value={value}
                onChange={handleChange}
                centered
            >
                <Tab label="Description" />
                <Tab label="Shipping & Returns" />
                <Tab label="Reviews" />
            </Tabs>
            <TabPanel value={value} index={0}>
                <Typography>{productData.description}</Typography>
            </TabPanel>

            <TabPanel value={value} index={1}>
                <Typography>Item Two Content</Typography>
            </TabPanel>

            <TabPanel value={value} index={2}>
                <Typography>
                    <RatingReview productId={productId} />
                </Typography>
            </TabPanel>
        </Box>
    );
}
