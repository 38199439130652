import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';
import { Navigation, Scrollbar } from 'swiper/modules';
import Container from '@mui/material/Container';
import ProductCard from '../product/ProductCard';
import SectionTitle from '../core/SectionTitle';
import { useTheme } from "@mui/material/styles";
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from "@mui/material/Box";
import KeyboardArrowLeftIcon  from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import CategoryFilter from './CategoryFilter';
import { Button } from '@mui/material';
import CustomSlider from './CustomSlider';

export default function NewProducts({ allProducts }) {
    const theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));
    const [selectedCategoryId, setSelectedCategoryId] = useState(0);

    const handleCategoryChange = (category) => {
        setSelectedCategoryId(category);
    };



    const filteredProducts = selectedCategoryId === 0 ? allProducts : allProducts.filter(item => item.category_id === selectedCategoryId);




    return (
        <Container maxWidth="lg" sx={{ mt: 5 }}>

            <Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between", alignItems: "center" }}>
                <SectionTitle t1={"New Products"} t2={"Discover the Latest Arrivals"} />
                <CategoryFilter
                    selectedCategoryId={selectedCategoryId}
                    onCategoryChange={handleCategoryChange}
                />

            </Box>

            <CustomSlider
        items={filteredProducts}
        renderSlide={(item) => <ProductCard productData={item} />}
        maxSlides={10}
        navigationClass="new-products"
      />

        </Container>
    );
}
