import React from 'react';
import { Container, Grid, Typography, Card, CardContent, Avatar, Box, useMediaQuery, useTheme } from '@mui/material';

// Sample team members data
const teamMembers = [
  {
    name: 'John Doe',
    role: 'CEO & Founder',
    image: 'https://via.placeholder.com/150',
    bio: 'John is the visionary behind the company with over 10 years of experience in the industry.',
  },
  {
    name: 'Jane Smith',
    role: 'CTO',
    image: 'https://via.placeholder.com/150',
    bio: 'Jane leads the tech team with her expertise in software development and innovation.',
  },
  {
    name: 'David Wilson',
    role: 'COO',
    image: 'https://via.placeholder.com/150',
    bio: 'David ensures that operations run smoothly and efficiently.',
  },
];

const AboutUs = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Container sx={{my:2}} maxWidth="lg" >
      <Grid container spacing={4}>
        {/* About Us Text Section */}
        <Grid item xs={12} md={6}>
          <Typography
            variant={isSmallScreen ? 'h4' : 'h3'}
            gutterBottom
            fontFamily="Playfair Display"
            fontWeight={500}
          >
            About Us
          </Typography>
          <Typography variant={isSmallScreen ? 'body2' : 'body1'} paragraph>
            We are a dedicated team, focused on delivering quality products and services to our customers. Our goal is to meet
            the needs of our clients while providing excellent service and fostering strong relationships.
          </Typography>
          <Typography variant={isSmallScreen ? 'body2' : 'body1'} paragraph>
            With a mission to create sustainable solutions, we aim to bring the best products to market while ensuring that
            our values align with those of our customers and partners.
          </Typography>
          <Typography variant={isSmallScreen ? 'body2' : 'body1'} paragraph>
            Over the years, we have built a reputation for reliability, innovation, and customer satisfaction. Our team
            consists of experts in various fields, working together to achieve one common goal: to provide exceptional value
            to our clients. We believe in the power of collaboration, and it is this synergy that allows us to consistently
            deliver high-quality results.
          </Typography>
        </Grid>

        {/* About Us Image */}
        <Grid item xs={12} md={6}>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <img
              src="https://res.cloudinary.com/dbi8wjrcl/image/upload/v1729788948/vegingo/pexels-fauxels-3183190_fua0sa.jpg"
              alt="About Us"
              loading="lazy"
              style={{
                maxWidth: '100%',
                borderRadius: '8px',
                maxHeight: isSmallScreen ? '250px' : '400px',
              }}
            />
          </Box>
        </Grid>
      </Grid>

      {/* Team Section */}
      <Box sx={{ marginTop: 10 }}>
        <Typography
          variant={isSmallScreen ? 'h5' : 'h4'}
          gutterBottom
          align="center"
          fontFamily="Playfair Display"
          fontWeight={500}
        >
          Meet Our Team
        </Typography>
        <Grid container spacing={4} justifyContent="center">
          {teamMembers.map((member, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card sx={{ height: '100%' }}>
                <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
                  <Avatar alt={member.name} src={member.image} sx={{ width: 100, height: 100 }} />
                </Box>
                <CardContent>
                  <Typography variant="h6" align="center">
                    {member.name}
                  </Typography>
                  <Typography variant="subtitle1" align="center" color="textSecondary">
                    {member.role}
                  </Typography>
                  <Typography variant="body2" align="center" color="textSecondary">
                    {member.bio}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
};

export default AboutUs;
