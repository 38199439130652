import React from 'react';
import Box from "@mui/material/Box"
import CircularProgress from '@mui/material/CircularProgress';
import { useTheme } from '@mui/material/styles';
const Loading = () => {
    const theme = useTheme();

    return (<Box
        sx={{
            position: "fixed",
            top: "0",
            left: "0",
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            width: "100%",
            background: theme.palette.background.default,
            zIndex: "1200"
        }}
    >
        <CircularProgress sx={{color:theme.palette.primary.main}} />
    </Box>

    )
};

export default Loading;
