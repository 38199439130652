import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { toast } from 'react-toastify';
import Error from '../../components/core/Error';
import api from '../../apiCollection';

const formatToIST = (dateString) => {
    const options = { timeZone: 'Asia/Kolkata', hour12: false };
    return new Date(dateString).toLocaleString('en-IN', options);
};

const CommentBox = ({ blogId, blogComments = [] }) => {
    const { user } = useSelector((state) => state.auth);
    const [comment, setComment] = useState('');
    const [comments, setComments] = useState(blogComments);
    const [loading, setLoading] = useState(false);
    const [editingCommentId, setEditingCommentId] = useState(null);
    const [updatedCommentText, setUpdatedCommentText] = useState('');
    const theme = useTheme();

    useEffect(() => {
        if (blogComments.length > 0) {
            setComments(blogComments);
            setLoading(false);
        }
    }, [blogComments]);

    const handleCommentChange = (event) => {
        setComment(event.target.value);
    };

    const handleUpdateCommentChange = (event) => {
        setUpdatedCommentText(event.target.value);
    };

    const handleCommentSubmit = async () => {
        if (comment.trim()) {
            try {
                const token = user?.token || null;
                if (!token) {
                    toast.error('User is not authenticated');
                    return;
                }

                const response = await axios.post(
                    `${api.blogComments.AddBlogComment}/${blogId}`,
                    { comment },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );

                const newComment = {
                    id: response.data.comment.id,
                    comment: response.data.comment.comment,
                    user: { name: user.name || 'User' },
                    created_at: new Date().toISOString(),
                };

                setComments((prevComments) => [...prevComments, newComment]);
                setComment('');
            } catch (error) {
                const errorMessage =
                    error.response?.data?.error || 'Error adding comment';
                toast.error(errorMessage, { position: 'top-center' });
                return <Error code={error.response?.status || 500} />;
            }
        }
    };

    const handleUpdateComment = async (commentId) => {
        if (updatedCommentText.trim()) {
            try {
                const token = user?.token || null;
                if (!token) {
                    toast.error('User is not authenticated');
                    return;
                }

                const response = await axios.put(
                    `${api.blogComments.UpdateBlogComment}/${commentId}`,
                    { comment: updatedCommentText },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );

                const updatedComment = {
                    id: commentId,
                    comment: response.data.comment.comment,
                    user: { name: user.name || 'User' },
                    created_at: new Date().toISOString(),
                };

                setComments((prevComments) =>
                    prevComments.map((c) =>
                        c.id === commentId ? updatedComment : c
                    )
                );

                setUpdatedCommentText('');
                setEditingCommentId(null);
            } catch (error) {
                const errorMessage =
                    error.response?.data?.error || 'Error updating comment';
                toast.error(errorMessage, { position: 'top-center' });
            }
        }
    };

    const handleDeleteComment = async (commentId) => {
        try {
            const token = user?.token || null;
            if (!token) {
                toast.error('User is not authenticated');
                return;
            }

            await axios.delete(
                `${api.blogComments.DeleteBlogComment}/${commentId}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            setComments((prevComments) =>
                prevComments.filter((comment) => comment.id !== commentId)
            );
            toast.success('Comment deleted successfully!');
        } catch (error) {
            const errorMessage =
                error.response?.data?.error || 'Error deleting comment';
            toast.error(errorMessage, { position: 'top-center' });
        }
    };

    if (loading) {
        return <Typography>Loading comments...</Typography>;
    }

    return (
        <Paper
            elevation={3}
            sx={{
                p: 3,
                marginTop: '20px',
                bgcolor: theme.palette.background.paper,
            }}
        >
            <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2 }}>
                {comments.length} Comments
            </Typography>
            <List
                className="scroll"
                sx={{
                    maxHeight: '300px',
                    overflow: 'auto',
                    '&::-webkit-scrollbar-thumb': {
                        background: theme.palette.primary.main,
                    },
                }}
            >
                {comments.map((comment) => (
                    <ListItem
                        key={comment.id}
                        style={{
                            borderBottom: '1px solid #ccc',
                            padding: '10px 0',
                        }}
                    >
                        <ListItemAvatar>
                            <Avatar>
                                {comment.user.name
                                    ? comment.user.name.charAt(0)
                                    : 'U'}
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary={
                                <Typography
                                    variant="subtitle1"
                                    sx={{ fontWeight: 'bold' }}
                                >
                                    {comment.user.name}
                                </Typography>
                            }
                            secondary={
                                <Box>
                                    {editingCommentId === comment.id ? (
                                        <TextField
                                            value={updatedCommentText}
                                            onChange={handleUpdateCommentChange}
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            sx={{ marginBottom: 1 }}
                                        />
                                    ) : (
                                        <Typography
                                            sx={{
                                                fontSize: '1.1rem',
                                                lineHeight: 1.5,
                                            }}
                                        >
                                            {comment.comment}
                                        </Typography>
                                    )}
                                    <Typography
                                        sx={{
                                            fontSize: '0.9rem',
                                            color: 'text.secondary',
                                        }}
                                    >
                                        {formatToIST(comment.created_at)}
                                    </Typography>
                                </Box>
                            }
                        />
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            {user.id === comment.user.id && (
                                <>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        onClick={() => {
                                            if (
                                                editingCommentId === comment.id
                                            ) {
                                                handleUpdateComment(comment.id);
                                            } else {
                                                setEditingCommentId(comment.id);
                                                setUpdatedCommentText(
                                                    comment.comment
                                                );
                                            }
                                        }}
                                        sx={{ marginLeft: 1 }}
                                    >
                                        {editingCommentId === comment.id
                                            ? 'Update'
                                            : 'Edit'}
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        color="secondary"
                                        onClick={() =>
                                            handleDeleteComment(comment.id)
                                        }
                                        sx={{ marginLeft: 1 }}
                                    >
                                        Delete
                                    </Button>
                                </>
                            )}
                        </Box>
                    </ListItem>
                ))}
            </List>

            <Box sx={{ mt: 4 }}>
                <TextField
                    label="Write a comment..."
                    required
                    multiline
                    rows={4}
                    variant="outlined"
                    value={comment}
                    onChange={handleCommentChange}
                    fullWidth
                    margin="normal"
                />
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleCommentSubmit}
                    style={{
                        marginTop: '10px',
                        color: 'white',
                        borderRadius: '1.2rem',
                        width: '100%',
                    }}
                >
                    Submit
                </Button>
            </Box>
        </Paper>
    );
};

export default CommentBox;
