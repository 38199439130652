import React, { useEffect, useState } from 'react';
import AppBar from "@mui/material/AppBar";
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Slide from "@mui/material/Slide";
import MenuBar from './MenuBar';
import useMediaQuery from '@mui/material/useMediaQuery';
import TopBar from "./TopBar";
import MobileNavbar from './MobileNavbar';
import { useDispatch, useSelector } from 'react-redux';
import Toolbar from '@mui/material/Toolbar';
import { fetchLocalCart, fetchServerCart } from '../../redux/slices/cartSlice';
import { fetchLocalWishlist, fetchServerWishlist } from '../../redux/slices/wishlistSlice';


const Navbar = () => {
  const isMediumScreen = useMediaQuery(theme => theme.breakpoints.down("md"));
  const trigger = useScrollTrigger();

  const { isAuthenticated } = useSelector((state) => state.auth);


  const dispatch = useDispatch();


  useEffect(()=>{
      if(isAuthenticated){
          dispatch(fetchServerCart());
          dispatch(fetchServerWishlist());
      }

      else{
          dispatch(fetchLocalCart());
          dispatch(fetchLocalWishlist());
          
      }
      
  },[dispatch,isAuthenticated])
     

  return (
    <>
      {!isMediumScreen && <TopBar />}

      <Slide appear={false} direction="down" in={!trigger}>
        <AppBar
          position='sticky'
          sx={{ transition: 'all 0.3s ease-in-out', top: 0, left: 0, bgcolor: 'background.default' }}

        >
          <Toolbar>
            <MenuBar />


          </Toolbar>
        </AppBar>
      </Slide>


      {/* Mobile navigation for small screens */}
      {isMediumScreen && <MobileNavbar />}
    </>
  );
};

export default Navbar;
