import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
// import { useNavigate } from 'react-router-dom'; 

const Error = ({code}) => {
//   const navigate = useNavigate();

//   const handleGoHome = () => {
//     navigate('/'); 
//   };

  return (
    <Box sx={{height:"100%", width:"100%",minHeight:"400px" ,display:"flex", justifyContent:"center", alignItems:"center",  bgcolor:"background.default",zIndex:1500 }}>

        <Box sx={{textAlign:"center"}}>
        <Typography variant="h1" color="error">
        {code}
      </Typography>
      <Typography variant="h3" color="error">
        Oops! Something went wrong.
      </Typography>
      <Typography variant="h6" sx={{ mt: 2, mb: 4 }}>
        We encountered an unexpected error. Please try again later.
      </Typography>
      {/* <Button variant="contained" color="primary" onClick={handleGoHome}>
        Go to Home
      </Button> */}

        </Box>
      
    </Box>
  );
};

export default Error;
