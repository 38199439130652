import axios from 'axios';
import localStorageHelper from '../helpers/localStorageHelper';
import { api } from '../apiCollection';

const login = async (email, password) => {
    try {
        const formData = new FormData();
        formData.append('email', email);
        formData.append('password', password);

        const response = await axios.post(api.auth.login, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });

        return response.data;
    } catch (error) {
        if (error.response) {
            console.error(
                'Error logging in: Server responded with an error',
                error.response.data
            );
            throw new Error(
                `Login failed: ${
                    error.response.data.message || error.response.statusText
                }`
            );
        } else if (error.request) {
            console.error(
                'Error logging in: No response received',
                error.request
            );
            throw new Error(
                'Login failed: No response received from the server. Please try again later.'
            );
        } else {
            console.error('Error logging in: Unexpected error', error.message);
            throw new Error(`Login failed: ${error.message}`);
        }
    }
};

const signup = async (
    name,
    email,
    phone_number,
    password,
    password_confirmation
) => {
    try {
        const formData = new FormData();
        formData.append('name', name);
        formData.append('email', email);
        formData.append('phone_number', phone_number);
        formData.append('password', password);
        formData.append('password_confirmation', password_confirmation);

        const response = await axios.post(api.auth.signUp, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });

        return response.data;
    } catch (error) {
        if (error.response) {
            console.error(
                'Error signing up: Server responded with an error',
                error.response.data
            );

            const validationErrors = error.response.data?.validation_error;

            if (validationErrors) {
                const errorMessages = Object.entries(validationErrors)
                    .map(([field, messages]) => messages.join(', '))
                    .join(' ');

                throw new Error(`${errorMessages}`);
            }

            throw new Error(
                `Signup failed: ${
                    error.response.data.message || error.response.statusText
                }`
            );
        } else if (error.request) {
            console.error(
                'Error signing up: No response received',
                error.request
            );
            throw new Error(
                'Signup failed: No response received from the server. Please try again later.'
            );
        } else {
            console.error('Error signing up: Unexpected error', error.message);
            throw new Error(`Signup failed: ${error.message}`);
        }
    }
};

const googleLogin = async (code) => {
    try {
        const response = await axios.get(`${api.google.callback}?code=${code}`);
        return response.data;
    } catch (error) {
        if (error.response) {
            console.error(
                'Error during Google login: Server responded with an error',
                error.response.data
            );
            throw new Error(
                `Google login failed: ${
                    error.response.data.message || error.response.statusText
                }`
            );
        } else if (error.request) {
            console.error(
                'Error during Google login: No response received',
                error.request
            );
            throw new Error(
                'Google login failed: No response received from the server. Please try again later.'
            );
        } else {
            console.error(
                'Error during Google login: Unexpected error',
                error.message
            );
            throw new Error(`Google login failed: ${error.message}`);
        }
    }
};

const logout = () => {
    try {
        localStorageHelper.removeItem('user');
    } catch (error) {
        console.error('Error logging out:', error);
        throw new Error('Logout failed: Unable to remove user data.');
    }
};

const getCurrentUser = () => {
    try {
        return localStorageHelper.getItem('user');
    } catch (error) {
        console.error('Error retrieving current user:', error);
        throw new Error('Unable to retrieve current user data.');
    }
};

export default {
    login,
    signup,
    logout,
    getCurrentUser,
    googleLogin,
};
