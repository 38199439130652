import React from 'react';
import PageTitle from '../components/core/PageTitle';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import ProductDetail from '../components/product/ProductDetail';
import { useLocation } from 'react-router-dom';
import useAllData from '../hooks/useAllData';
import Loading from '../components/core/Loading';
import CenteredTabs from '../components/product/CenteredTabs';
import { api } from '../apiCollection';
import { toast } from 'react-toastify';
import Error from '../components/core/Error';

export default function ProductFullDetails() {
    const location = useLocation();
    const productName = decodeURIComponent(
        location.pathname.split('/').pop().trim()
    );
    console.log(productName);

    const productUrl = api.products.getAll;

    const { allData, loading, error } = useAllData(productUrl);

    if (loading) {
        return <Loading />;
    }

    if (error) {
        toast.error('Error fetching product data', {
            position: 'top-center',
        });
        return <Error code={error.request.status} />;
    }

    const productData = allData.find((item) => item.name === productName);

    if (!productData) {
        return (
            <Container sx={{ my: 10, textAlign: 'center' }} maxWidth="lg">
                <Typography variant="h1" color="error">
                    404
                </Typography>
                <Typography variant="h3" color="error">
                    Product not found
                </Typography>
            </Container>
        );
    }

    return (
        <>
            <PageTitle title="Product" />
            <Container sx={{ my: 10 }} maxWidth="lg">
                <ProductDetail productData={productData} />
                <CenteredTabs productData={productData} />
            </Container>
        </>
    );
}
