import { useTheme } from '@mui/material/styles';
import Box from "@mui/material/Box"
import  LinearProgress  from '@mui/material/LinearProgress';
import React from 'react'

export default function ProgressBar({progress}) {
  const theme = useTheme();
  return (
    <Box sx={{width:"200px"}}>
        <LinearProgress sx={{height:"10px",borderRadius:10,"& .MuiLinearProgress-bar":{
            bgcolor:theme.palette.primary.main
        }}}  variant="determinate" value={progress} />
    </Box>
  )
}
