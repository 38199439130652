import React from "react";
import { Box, Grid, Typography, Button, CardMedia, Container } from "@mui/material";
import InstallIcon from "@mui/icons-material/InstallMobile";
import { Link } from 'react-router-dom';

const InstallApp = () => {
  return (
    <Box
      sx={{
        background:
          "url(https://res.cloudinary.com/dbi8wjrcl/image/upload/v1729676497/vegingo/f7f7f7_3_pio8fb.png)",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Container maxWidth="lg" sx={{ my: 10 }}>
        <Box>
          <Grid container rowGap={4} columnSpacing={4} alignItems="center">
            {/* Left Text Section */}
            <Grid item xs={12} md={6}>
              <Typography
                variant="h4"
                component="h2"
                gutterBottom
                sx={{
                  color:"black",
                  fontFamily: "Playfair Display",
                  fontWeight: "bold",
                  fontSize: { xs: "1.8rem", sm: "2.4rem" }, // Adjust font size for small screens
                  textAlign: { xs: "center", md: "left" }, // Center text on small screens
                }}
              >
                Get Our App Today!
              </Typography>

              <Typography
                variant="body1"
                sx={{color:"black", mb: 2, textAlign: { xs: "center", md: "left" }, fontSize: { xs: "0.9rem", sm: "1rem" } }}
              >
                Make your shopping experience even more convenient with our mobile app.
              </Typography>

              <Typography
                variant="body2"
                color="textSecondary"
                paragraph
                sx={{color:"black", textAlign: { xs: "center", md: "left" }, fontSize: { xs: "0.85rem", sm: "1rem" } }}
              >
                Download the app and get access to exclusive features. Track your orders, receive special offers, and much more.
              </Typography>

              {/* Download Button */}
              <Box sx={{ display: "flex", justifyContent: { xs: "center", md: "flex-start" } }}>
                <Link to="/install">
                  <Button startIcon={<InstallIcon />} variant="contained" color="secondary">
                    Download
                  </Button>
                </Link>
              </Box>
            </Grid>

            {/* Right Image Section */}
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "1rem",
                }}
              >
                {/* Image 1 */}
                <CardMedia
                  sx={{
                    borderRadius: "16px",
                    height: { xs: "200px", sm: "250px", md: "350px", lg: "400px" }, // Smaller height for small screens
                    width: { xs: "100%", md: "48%" },
                    mb: { xs: 2, md: 5 },
                    overflow: "hidden",
                  }}
                >
                  <Box
                    component="img"
                    src="https://res.cloudinary.com/dbi8wjrcl/image/upload/v1729670959/vegingo/Untitled_design_17_exsgjv.png"
                    
                    
                    sx={{
                      width: "100%",
                      height: "100%",
                      transition: "transform 0.3s ease-in-out",
                      "&:hover": {
                        transform: "scale(1.05)",
                      },
                    }}
                  />
                </CardMedia>

                {/* Image 2 */}
                <CardMedia
                  sx={{
                    borderRadius: "16px",
                    height: { xs: "200px", sm: "250px", md: "350px", lg: "400px" }, // Smaller height for small screens
                    width: { xs: "100%", md: "48%" },
                    mt: { xs: 2, md: 5 },
                    overflow: "hidden",
                  }}
                >
                  <Box
                    component="img"
                    src="https://res.cloudinary.com/dbi8wjrcl/image/upload/v1729669774/vegingo/Untitled_design_16_ucttra.png"
                    
                    sx={{
                      width: "100%",
                      height: "100%",
                      transition: "transform 0.3s ease-in-out",
                      "&:hover": {
                        transform: "scale(1.05)",
                      },
                    }}
                  />
                </CardMedia>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default InstallApp;
