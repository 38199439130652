import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';
import { Navigation, Scrollbar } from 'swiper/modules';
import Container from '@mui/material/Container';
import ProductCard from '../product/ProductCard';
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Grid from "@mui/material/Grid";
import Button from '@mui/material/Button';
import Divider from "@mui/material/Divider";
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import CategoryFilter from './CategoryFilter';
import CustomSlider from './CustomSlider';
import SectionTitle from '../core/SectionTitle';


export default function OurProducts({ allProducts }) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [selectedCategoryId, setSelectedCategoryId] = useState(0); 

  const handleCategoryChange = (category) => {
      setSelectedCategoryId(category);
    };



    const filteredProducts = selectedCategoryId === 0 ? allProducts : allProducts.filter(item=>item.category_id === selectedCategoryId);

  return (
    <Container maxWidth="lg" sx={{ mt: 10 }}>
       <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap" }}>
        <SectionTitle t1="Our Products" t2="Explore Our Full Range" />
        <CategoryFilter selectedCategoryId={selectedCategoryId} onCategoryChange={handleCategoryChange} />
      </Box>
        
        <CustomSlider
        items={filteredProducts}
        renderSlide={(item) => <ProductCard productData={item}  />}
        maxSlides={10}
        navigationClass="our-products"
      />
        
     
    </Container>
  );
}
