import { Box, Button, Container, Grid, Paper, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import AddressList from '../components/account/AddressList'
import { useDispatch, useSelector } from 'react-redux'
import { fetchAddress } from '../redux/slices/addressSlice'
import Loading from '../components/core/Loading'
import ReceiptIcon from '@mui/icons-material/Receipt';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import DiscountIcon from '@mui/icons-material/Discount';
import { Link } from 'react-router-dom'


export default function Address() {
  const dispatch = useDispatch();
  const { billDetails, selectedAddress, orderNote } = useSelector(state => state.order);
  console.log(billDetails)

  useEffect(() => {
    dispatch(fetchAddress())
  }, [dispatch]);

  const {addresses} = useSelector(state=>state.address);

  const { status } = useSelector(state => state.address);

  if (status === 'loading') {
    return <Loading />
  }

  return (
    <Container maxWidth="lg">
      <Grid container columnSpacing={2} rowGap={5}>
        <Grid item md={8} xs={12}>
          <AddressList enableRadio="true" />

        </Grid>

        <Grid item md={4} xs={12}>
          <Paper elevation={2} sx={{ p: 2, position: 'sticky', top: 20 }}>
            <Box sx={{ py: 2 }}>
              <Typography variant='body2' gutterBottom sx={{ fontWeight: 800, textTransform: "upper" }}>Bill details</Typography>

              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                  <ReceiptIcon sx={{ fontSize: "1rem" }} />
                  <Typography variant='body2'>Sub Total</Typography>
                </Box>
                <Typography variant='body2'>₹ {billDetails.cartTotal}</Typography>
              </Box>

              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                  <DeliveryDiningIcon sx={{ fontSize: "1rem" }} />
                  <Typography variant='body2'>Delivery Charge</Typography>
                </Box>
                <Typography variant='body2'>{billDetails.deliveryCharge === 0 ? "FREE" : `- ₹ ${billDetails.deliveryCharge}`}</Typography>
              </Box>


              {
                billDetails.discount > 0 && <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                    <DiscountIcon sx={{ fontSize: "1rem" }} />
                    <Typography variant='body2'>Coupon Discount</Typography>
                  </Box>
                  <Typography variant='body2' color="primary">- ₹ {billDetails.discount}</Typography>
                </Box>
              }




              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography variant='subtitle2'>Grand Total</Typography>
                <Typography variant='subtitle2'>₹ {billDetails.grandTotal}</Typography>
              </Box>
            </Box>


            <Box sx={{ mt: 2 }}>
              <Typography variant="subtitle2" gutterBottom sx={{ fontWeight: 'bold' }}>
                Delivery Address
              </Typography>

              {selectedAddress ? (
                <Box>
                  <Typography variant='body2'>{selectedAddress.user_name}, {selectedAddress.pincode}</Typography>
                  <Typography variant='body2'>{selectedAddress.building_name_area}, {selectedAddress.city}, {selectedAddress.state}, {selectedAddress.country}</Typography>
                  <Typography variant='body2'>Mobile: <strong>{selectedAddress.phone_number}</strong></Typography>
                </Box>
              ) : (
                <Typography variant='body2'>No address selected.</Typography>
              )}
            </Box>

            <Box sx={{ mt: 2, display: "flex", gap: 1 }}>
              <Typography fontWeight={600} variant='body2'>Order Note: </Typography>
              <Typography variant='body2'>{orderNote}</Typography>
            </Box>


            <Link to={"/checkout/payment"}>
              <Button disabled={addresses.length === 0} variant='contained' fullWidth sx={{ color: "white", mt: 2 }}>
                Continue
              </Button>
            </Link>


          </Paper>



        </Grid>
      </Grid>



    </Container>
  )
}
