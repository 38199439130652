// src/pages/OrderConfirmed.js

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Typography, Container, Box } from '@mui/material';

const OrderConfirmed = () => {
    const navigate = useNavigate();

    const handleRedirect = () => {
        navigate("/account/orders");
    };

    return (
        <Box sx={{ position: "fixed", top: 0, left: 0, height: "100%", width: "100%", zIndex: 2000, bgcolor: "white" }}>
            <Container maxWidth="lg" sx={{ height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }} textAlign="center">
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", textAlign: "center" }}>
                    <img src="/green-check.webp" width={200} alt='order confirm green tick' />
                    <Typography sx={{ mt: 2 }} variant="h4" >
                        Order Confirmed!
                    </Typography>
                    <Typography variant="h6" gutterBottom>
                        Thank you for your purchase. Your order has been successfully placed.
                    </Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleRedirect}
                        sx={{ color: "white", borderRadius: "1.2rem", mt: 2 }}
                    >
                        Go to Order History
                    </Button>

                </Box>

            </Container>

        </Box>



    );
};

export default OrderConfirmed;
