import React, { useState } from 'react';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import contactService from '../services/contactService';
import { toast } from 'react-toastify';

export default function ContactUs() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        message: '',
    });

    const [errors, setErrors] = useState({
        name: '',
        email: '',
        subject: '',
        message: '',
    });

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [id]: value,
        }));

        setErrors((prevErrors) => ({
            ...prevErrors,
            [id]: '',
        }));
    };

    const validateForm = () => {
        let tempErrors = { name: '', email: '', subject: '', message: '' };
        let isValid = true;

        if (!formData.name.trim()) {
            tempErrors.name = 'Name is required';
            isValid = false;
        }

        if (!formData.email.trim()) {
            tempErrors.email = 'Email is required';
            isValid = false;
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            tempErrors.email = 'Email address is invalid';
            isValid = false;
        }

        if (!formData.subject.trim()) {
            tempErrors.subject = 'Subject is required';
            isValid = false;
        }

        if (!formData.message.trim()) {
            tempErrors.message = 'Message is required';
            isValid = false;
        }

        setErrors(tempErrors);
        return isValid;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) return;

        try {
            const response = await contactService.submitContactForm(
                formData.name,
                formData.email,
                formData.subject,
                formData.message
            );
            console.log('Form submitted successfully', response);
            toast.success('Form submitted successfully', {
                position: 'top-center',
            });

            setFormData({ name: '', email: '', subject: '', message: '' });
        } catch (error) {
            console.error('Error submitting form:', error);
            toast.error('Error submitting form', { position: 'top-center' });
        }
    };

    return (
        <Container maxWidth="lg">
            <Box my={5}>
                <Grid container columnSpacing={6} rowGap={4}>
                    <Grid item xs={12} md={5}>
                        <Box sx={{ width: '100%', height: '100%' }}>
                            <img
                                loading="lazy"
                                src="https://res.cloudinary.com/dbi8wjrcl/image/upload/q_auto:low/v1729839998/vegingo/pexels-yankrukov-8867434_1_diuzny.jpg"
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                    borderRadius: 5,
                                }}
                                alt=""
                            />
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={7}>
                        <form
                            noValidate
                            autoComplete="off"
                            onSubmit={handleSubmit}
                        >
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        id="name"
                                        label="Name"
                                        variant="outlined"
                                        fullWidth
                                        required
                                        value={formData.name}
                                        onChange={handleChange}
                                        error={!!errors.name}
                                        helperText={errors.name}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="email"
                                        label="Email"
                                        variant="outlined"
                                        type="email"
                                        fullWidth
                                        required
                                        value={formData.email}
                                        onChange={handleChange}
                                        error={!!errors.email}
                                        helperText={errors.email}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="subject"
                                        label="Subject"
                                        variant="outlined"
                                        fullWidth
                                        required
                                        value={formData.subject}
                                        onChange={handleChange}
                                        error={!!errors.subject}
                                        helperText={errors.subject}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="message"
                                        label="Message"
                                        variant="outlined"
                                        multiline
                                        rows={4}
                                        fullWidth
                                        required
                                        value={formData.message}
                                        onChange={handleChange}
                                        error={!!errors.message}
                                        helperText={errors.message}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            my: 2,
                                        }}
                                    >
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            sx={{ color: 'white' }}
                                        >
                                            Send
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
}
