import React, { useEffect, useState, useCallback } from 'react';
import { Container, Grid, Box, Button, Typography, CircularProgress, useMediaQuery } from '@mui/material';
import ProductCard from '../product/ProductCard';
import Header from './Header';
import { useTheme } from '@mui/material/styles';
import Filters from './Filters';
import usePaginatedData from '../../hooks/usePaginatedData';
import api from '../../apiCollection';
import Loading from '../core/Loading';
import Error from '../core/Error';
import ProgressBar from '../core/ProgressBar';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';

export default function Collection() {
  const { category } = useParams();
  const navigate = useNavigate();

  const productUrl = api.products.getAll;
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const { data, currentPage, loading, error, loadMore, totalCount } = usePaginatedData(productUrl);

  console.log(data);
  const [filteredData, setFilteredData] = useState([]);
  const [sortedData, setSortedData] = useState([]);
  const [sortOption, setSortOption] = useState('priceLowHigh');
  const [view, setView] = useState('grid');
  const [selectedFilters, setSelectedFilters] = useState({
    is_in_stock: [],
    price: [],
    category_id: [],
    moreFilters: [],
    brand: [],
    size: []
  });

  // Filter products based on category
  useEffect(() => {
    if (category) {
      const filteredProducts = data.filter(product => product.tags.includes(category));
      setFilteredData(filteredProducts);
    } else {
      setFilteredData(data);
    }
  }, [category, data]);

  // Sort products based on selected sort option
  const handleSort = useCallback((products) => {
    let sorted = [...products];
    sorted.sort((a, b) => {
      if (sortOption === 'priceLowHigh') {
        return a.price - b.price;
      }
      if (sortOption === 'priceHighLow') {
        return b.price - a.price;
      }
      return 0;
    });
    return sorted;
  }, [sortOption]);

  // Apply filters on the products
  const handleFilters = useCallback((products) => {
    return products.filter(product => {
      return Object.keys(selectedFilters).every(filterKey => {
        if (selectedFilters[filterKey].length === 0) {
          return true;
        }
        if (filterKey === 'price') {
          return selectedFilters.price.some(priceRange => {
            if (priceRange === 'under20') return (product.discounted_price ? product.discounted_price < 20 : product.price < 20);
            if (priceRange === '21to50') return (product.discounted_price ? product.discounted_price >= 21 && product.discounted_price <= 50 : product.price >= 21 && product.price <= 50);
            if (priceRange === '51to100') return (product.discounted_price ? product.discounted_price >= 51 && product.discounted_price <= 100 : product.price >= 51 && product.price <= 100);
            if (priceRange === '101to200') return (product.discounted_price ? product.discounted_price >= 101 && product.discounted_price <= 200 : product.price >= 101 && product.price <= 200);
            if (priceRange === '201to500') return (product.discounted_price ? product.discounted_price >= 201 && product.discounted_price <= 500 : product.price >= 201 && product.price <= 500);
            if (priceRange === 'over500') return (product.discounted_price ? product.discounted_price > 500 : product.price > 500);
            return false;
          });
        }
        return selectedFilters[filterKey].includes(product[filterKey]);
      });
    });
  }, [selectedFilters]);

  // Apply filters and sorting to the data
  useEffect(() => {
    let filtered = handleFilters(filteredData);
    setSortedData(handleSort(filtered));
  }, [filteredData, sortOption, selectedFilters, handleFilters, handleSort]);

  // Handle category change
  const handleCategoryChange = (newCategory) => {
    navigate(`/shop/${newCategory}`);
  };

  if (loading && currentPage === 1) {
    return <Loading />;
  }
  if (error) {
    toast.error("Error fetching the data");
    return <Error code={error.request.status} />;
  }

  let progress = (sortedData.length / totalCount) * 100;

  return (
    <Box>
      <Container maxWidth="xl">
        {/* Category Filter Buttons */}
        <Box sx={{ display: 'flex', gap: 2, justifyContent: 'center', my: 3, flexWrap: "wrap" }}>
          <Button
            size="small"
            variant={category === 'indian' ? 'contained' : 'outlined'}
            onClick={() => handleCategoryChange('indian')}
            sx={{ color: category === 'indian' ? 'white' : 'inherit' }}
          >
            Indian
          </Button>
          <Button
            size="small"
            variant={category === 'chinese' ? 'contained' : 'outlined'}
            onClick={() => handleCategoryChange('chinese')}
            sx={{ color: category === 'chinese' ? 'white' : 'inherit' }}
          >
            Chinese
          </Button>
          <Button
            size="small"
            variant={category === 'soup veggies' ? 'contained' : 'outlined'}
            onClick={() => handleCategoryChange('soup veggies')}
            sx={{ color: category === 'soup veggies' ? 'white' : 'inherit' }}
          >
            Soup Veggies
          </Button>
          <Button
            size="small"
            variant={category === 'stir-fry veggies' ? 'contained' : 'outlined'}
            onClick={() => handleCategoryChange('stir-fry veggies')}
            sx={{ color: category === 'stir-fry veggies' ? 'white' : 'inherit' }}
          >
            Stir-fry Veggies
          </Button>
          <Button
            size="small"
            variant={category === 'salad veggies' ? 'contained' : 'outlined'}
            onClick={() => handleCategoryChange('salad veggies')}
            sx={{ color: category === 'salad veggies' ? 'white' : 'inherit' }}
          >
            Salad Veggies
          </Button>
          <Button
            size="small"
            variant={category === 'tropical' ? 'contained' : 'outlined'}
            onClick={() => handleCategoryChange('tropical')}
            sx={{ color: category === 'tropical' ? 'white' : 'inherit' }}
          >
            Tropical Veggies
          </Button>
          <Button
            size="small"
            variant={category === 'crisp' ? 'contained' : 'outlined'}
            onClick={() => handleCategoryChange('crisp')}
            sx={{ color: category === 'crisp' ? 'white' : 'inherit' }}
          >
            Crisp Veggies
          </Button>
          <Button
            size="small"
            variant={category === '' ? 'contained' : 'outlined'}
            onClick={() => handleCategoryChange('')}
            sx={{ color: category === '' ? 'white' : 'inherit' }}
          >
            All
          </Button>
        </Box>



        <Grid container spacing={2}>
          {!isMediumScreen && (
            <Grid item xs={12} md={2.5}>
              <Box sx={{ position: 'sticky', top: '20px', maxHeight: '100vh', overflow: 'auto', borderRight: '1px solid #ddd', paddingRight: 2 }}>
                <Filters selectedFilters={selectedFilters} setSelectedFilters={setSelectedFilters} />
              </Box>
            </Grid>
          )}

          <Grid item xs={12} md={9.5}>
            {/* Fixed Header */}
            <Box sx={{ position: 'sticky', top: '0px', zIndex: 2, bgcolor: theme.palette.background.default, p: 1 }}>
              <Header view={view} setView={setView} count={sortedData.length} sortOption={sortOption} setSortOption={setSortOption} selectedFilters={selectedFilters} setSelectedFilters={setSelectedFilters} />
            </Box>

            <Box sx={{ minHeight: "100vh" }}>
              <Grid container columnSpacing={2} rowGap={2}>
                {sortedData.map(product => (
                  <Grid  item xs={view === "grid" ? 6 : 12}  md={view === "grid" ? 3 : 12}  key={product.id}>
                    <ProductCard productData={product} flexDirection={view === "grid" ? "column" : "row"} button={true} />
                  </Grid>
                ))}
              </Grid>

              {/* Load more button and progress bar */}
              <Box sx={{ width: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", my: 10, gap: 2 }}>
                <Typography gutterBottom variant='h7' sx={{ fontWeight: "500" }}>
                  You are viewing {sortedData.length} of {totalCount} products
                </Typography>
                <ProgressBar progress={progress} />
                <Button size='small' variant='contained' disabled={sortedData.length === totalCount} onClick={loadMore} sx={{ bgcolor: theme.palette.primary.main, color: "white", width: "120px", whiteSpace: "nowrap" }}>
                  {loading ? <CircularProgress size={20} sx={{ color: "white" }} /> : <span>Load More</span>}
                </Button>
              </Box>
            </Box>

          </Grid>
        </Grid>


      </Container>
    </Box>
  );
}
