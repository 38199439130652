import React, { useEffect, useState } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Snackbar from '@mui/material/Snackbar';
import couponService from '../../services/cartService';
import CouponItem from './CouponItem';
import { useSelector } from 'react-redux';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: 2,
  overflow: 'hidden',
};

export default function CouponModal({ open, handleClose, onSelectCoupon }) {
  const [coupons, setCoupons] = useState([]);
  const [filteredCoupons, setFilteredCoupons] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCoupon, setSelectedCoupon] = useState(null);
  const [showGif, setShowGif] = useState(false);
  const localCart = useSelector(state => state.cart.localCart);
  const serverCart = useSelector(state => state.cart.serverCart);
  const { isAuthenticated } = useSelector(state => state.auth);
  const cart = isAuthenticated ? serverCart : localCart;
  



  useEffect(() => {
    const fetchCoupons = async () => {
      try {
        const response = await couponService.getAllCoupons();
        setCoupons(response.data);
        setFilteredCoupons(response.data);
      } catch (err) {
        console.error(err);
        setError('Failed to load coupons');
      } finally {
        setLoading(false);
      }
    };

    if (open) {
      fetchCoupons();
    }
  }, [open]);

  useEffect(() => {
    const filtered = coupons.filter((coupon) =>
      coupon.coupon_code.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredCoupons(filtered);
  }, [searchTerm, coupons]);

  const handleCouponSelect = (coupon) => {
    setSelectedCoupon(coupon);
    onSelectCoupon(coupon);
    handleClose();
    setShowGif(true);
  };

  const handleGifClose = () => {
    setShowGif(false);
  };

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <Typography variant="h6" component="h2" sx={{ px: 2, pt: 2 }}>
            Select a Coupon
          </Typography>
          <Divider sx={{ my: 2 }} />
          <Box sx={{ px: 2, pb: 1 }}>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Search for a coupon..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </Box>
          <Divider />
          <Box sx={{ height: '300px', overflowY: 'scroll', p: 2 }}>
            {loading ? (
              <Typography>Loading coupons...</Typography>
            ) : error ? (
              <Typography color="error">{error}</Typography>
            ) : filteredCoupons.length > 0 ? (
              filteredCoupons.map((coupon) => (
                <CouponItem
                  key={coupon.id}
                  coupon={coupon}
                  selectedCoupon={selectedCoupon}
                  onSelect={handleCouponSelect}
                />
              ))
            ) : (
              <Typography>No coupons available.</Typography>
            )}
          </Box>
        </Box>
      </Modal>


      {selectedCoupon &&
        <Snackbar
          open={showGif}
          autoHideDuration={3000}
          onClose={handleGifClose}
          message={
            <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
              <img
                src="https://media3.giphy.com/media/v1.Y2lkPTc5MGI3NjExN2pjZmpqeDVpNnI1bTA2bXc5MDN4MmFuYW1sbjZxc25rM2cwdGM4ayZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/nagxKKFv9vVCj2mB4J/giphy.webp"
                alt="Party Popper"
                style={{ width: '100px', height: '100px' }}
              />

              <Typography variant='body1'>
                Yay! You saved additional ₹ {selectedCoupon.discount_type === "percentage" ? cart.total/100 * selectedCoupon.discount_amount : parseInt(selectedCoupon.discount_amount)} with this coupon
              </Typography>

            </Box>
          }
        />

      }

    </>
  );
}
